import React from 'react'
import { useSignup } from '../../signupContext'
import { Grid, Typography } from '@material-ui/core'
import useEditChapterStyles from '../../styles/useEditChapterStyles'
import { settings } from '../../resources'
import { AppRoutes } from '../../application/types'
import useNavigateReplace from '../../hooks/useNavigateReplace'

const ConsumerOverview = ({ bigTitle = true }: Record<string, boolean>) => {
  const signup = useSignup()
  const navigate = useNavigateReplace()
  const classes = useEditChapterStyles()

  const editAddressEnabled = (((signup.data.company?.address  ?? []).length !== 1) || signup.data.session?.merchantconfig?.signup_allowed_manual_addresses?.includes("billing"))

  const editSelectedCompanyAddress = () => {
    navigate(AppRoutes.CHAPTER_COMPANY_ADDRESS_EDIT)
  }


  if (!signup.data.selectedCompanyAddress) {
    return null
  }

  return (
    <Grid container>

      <div style={{ display: 'flex',  flexDirection: 'row', width: '100%' }}>
        <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
          <div >
            <Typography variant="h5" component="h2" className={bigTitle ? classes.bigTitle : classes.smallTitle}>
              Adressinformation
            </Typography>
          </div>
        </div>
        {editAddressEnabled && (
          <div className={classes.editButton} onClick={editSelectedCompanyAddress} style={{ display: 'flex',  alignItems: 'flex-end', justifyContent: 'flex-end', width: '50%' }}>
            <Typography color="textPrimary" variant="body2" align="right" className={classes.changeButton}>
              {settings.companyChapter.companyOverview.editAddress}
            </Typography>
          </div>
          )}
      </div>

      {/* Content */}
      <Grid container className={classes.roundedBorder} data-testid="CompanyOverviewPreviewContainer">
        <Grid item>
          <Typography color="textPrimary" variant="body2" align="left">
            {signup.data.selectedCompanyAddress.streetaddress}, {signup.data.selectedCompanyAddress.zip}, {signup.data.selectedCompanyAddress.city}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ConsumerOverview
