import { useRef, useEffect } from 'react'

const asyncIntervals: boolean[] = [];

const runAsyncInterval = async (callback: () => void, interval: number, intervalIndex: number) => {
  await callback();
  if (asyncIntervals[intervalIndex]) {
    setTimeout(() => runAsyncInterval(callback, interval, intervalIndex), interval);
  }
};

const setAsyncInterval = (callback: () => void, interval: number) => {
  if (callback && typeof callback === "function") {
    const intervalIndex = asyncIntervals.length;
    asyncIntervals.push(true);
    runAsyncInterval(callback, interval, intervalIndex);
    return intervalIndex;
  } else {
    throw new Error('Callback must be a function');
  }
};

const clearAsyncInterval = (intervalIndex: number) => {
  if (asyncIntervals[intervalIndex]) {
    asyncIntervals[intervalIndex] = false;
  }
};

function useAsyncInterval(callback: () => void, delay: number | null) {
  const savedCallback = useRef<() => void | null>()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  })

  // Set up the interval.
  useEffect(() => {
    async function tick() {
      if (typeof savedCallback?.current !== 'undefined') {
        await savedCallback?.current()
      }
    }

    if (delay !== null) {
      const id = setAsyncInterval(async () => await tick(), delay)
      return () => clearAsyncInterval(id)
    }
  }, [delay])
}

export default useAsyncInterval
