import React, { useState } from 'react'
import { Grid, TextField } from '@material-ui/core'

interface Props {
    autoFocus?: boolean
    defaultValue?: string
    disabled: boolean
    Icon?: JSX.Element
    label: string
    left?: boolean
    name: string
    right?: boolean
    style?: React.CSSProperties
    testId: string
    validationError: string | undefined
    value?: string
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    
}

const IconTextInput = (props: Props) => {
  const { autoFocus, defaultValue, disabled, Icon, label, left, name, right, style, testId, validationError, value, onChange } = props

  const [focused, setFocused] = useState(false)

  return (
    <Grid item style={{ ...style, paddingLeft: left ? 10 : 5, paddingRight: right ? 10 : 5 }}>
      <Grid container spacing={1} alignItems="flex-start">
        {!!Icon && (
          <Grid item style={{marginTop: 20}}>
            {Icon}
          </Grid>
        )}
        <Grid item xs>
          <TextField
            name={name}
            label={label}
            data-testid={testId}
            defaultValue={defaultValue}
            value={value}
            onChange={onChange}
            onBlur={() => setFocused(false)}
            onFocus={() => setFocused(true)}
            error={!focused && !!validationError}
            helperText={!focused && validationError}
            disabled={disabled}
            autoFocus={autoFocus}
            fullWidth={true}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default IconTextInput
