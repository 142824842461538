import { Grid, Typography } from '@material-ui/core'
import React from 'react'
import { settings } from '../../resources'
import { AppRoutes } from '../../application/types'
import useNavigateReplace from '../../hooks/useNavigateReplace';
import { useSignup } from '../../signupContext'
import useEditChapterStyles from '../../styles/useEditChapterStyles';
import { getCustomFieldsConfig } from '../../signupHelper';

const AdditionalInformationOverview = ({ bigTitle = true }: Record<string, boolean>) => {
  const signup = useSignup()
  const navigate = useNavigateReplace()
  const classes = useEditChapterStyles()
    
  const customFieldsConfig = getCustomFieldsConfig(signup.data.session)
  const customFieldsData = signup.data.additionalInformation

  const editUserDetails = () => {
    navigate(AppRoutes.CHAPTER_ADDITIONAL_INFORMATION_EDIT)
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container justify="center">
          <Grid item className={classes.changeButton} onClick={editUserDetails} xs={12}>
            <Grid container direction="row">
              <Grid item xs={8}>
                <Typography variant="h5" component="h2" className={bigTitle ? classes.bigTitle : classes.smallTitle}>
                  {customFieldsConfig?.header}
                </Typography>
              </Grid>
              <Grid item xs={4} className={classes.editButtonSmall}>
                <Typography color="textPrimary" variant="body2" align="right">
                  {settings.userChapter.userChapterOverView.edit}
                </Typography>
              </Grid>
            </Grid>
            <Grid container className={classes.roundedBorder} style={{ rowGap: '10px', flexDirection: 'column' }} data-testid="UserOverviewPreviewContainer">
              {customFieldsConfig?.groups.filter(group => group?.rows?.some(row => row?.some(cf => cf?.showInOverview))).map((group, index) => (
                <div key={`cf-ov-${index}`}>
                  {!!group.header &&
                    <Grid item xs={12}>
                      <Typography color="textPrimary" align="left" className={classes.titleText}>
                        {group.header}
                      </Typography>
                    </Grid>
                  }
                  <Typography color="textPrimary" variant="body2" align="left">
                    {group.rows.flat().filter(cf => cf.showInOverview && customFieldsData?.[cf.fieldName]).map(cf => {
                      // For type switch we select labels, otherwise values are just true/false
                      if (cf.type === 'switch') {
                        return !!customFieldsData?.[cf.fieldName] && cf?.['label']
                      }
                      if (cf.showLabelInOverview && cf.label) {
                        return `${cf.label} - ${customFieldsData?.[cf.fieldName]}`
                      }
                      return customFieldsData?.[cf.fieldName]
                    }).join(', ')}
                  </Typography>
                </div>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AdditionalInformationOverview
