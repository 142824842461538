import jwt_decode from 'jwt-decode'

const apiBaseUrl = () => {
    if (isLocal())
        return 'http://localhost:8080/api'
    return '/api'
}

const getLocale = () => {
    const urlParams = new URLSearchParams(window.location.search)
    return urlParams.get('locale')|| 'sv-se'
}

const getMerchantName = () => {
    const token = getToken()
    const decodedToken = jwt_decode(token)
    return decodedToken.name
}

const getSessionId = () => {
    const token = getToken()
    const decodedToken = jwt_decode(token)
    return decodedToken.sessionId
}

const getTestMode = () => {
  const urlParams = new URLSearchParams(window.location.search)
  return urlParams.get("test") || ""
}

const getToken = () => {
    const urlParams = new URLSearchParams(window.location.search)
    return urlParams.get('token') || ''
}

const isLocal = () => {
    return /localhost/i.test(window.location.hostname)
}

export default {
    apiBaseUrl,
    getLocale,
    getMerchantName,
    getSessionId,
    getTestMode,
    getToken,
    isLocal,
}
