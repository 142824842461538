import React from 'react'
import { settings } from '../../resources'
import { Divider, Grid, makeStyles, Typography } from '@material-ui/core'
import { AppRoutes } from '../../application/types'
import useNavigateReplace from '../../hooks/useNavigateReplace';
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles((theme) => ({
  paragraph: {
    padding: theme.spacing(1.5),
  },
  closeIcon: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1)
  },
  headline: {
    paddingRight: theme.spacing(5)
  }
}))

const CreditTerms = () => {
  const navigate = useNavigateReplace()
  const classes = useStyles()

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container direction="row" justify="center">
            <Typography
              align="center"
              variant="h6"
              component="h2"
              className={classes.headline}
            >
              {settings.companyChapter.creditModal.header}
            </Typography>
            <CloseIcon cursor="pointer" fontSize="large" className={classes.closeIcon} onClick={() => navigate(AppRoutes.CHAPTER_COMPANY_COMPANY_LOOKUP)} />
          <Typography
            color="textPrimary"
            align="center"
            variant="body2"
            className={classes.paragraph}
          >
            {settings.companyChapter.creditModal.p1}
          </Typography>
          <Typography
            color="textPrimary"
            align="center"
            variant="body2"
            className={classes.paragraph}
          >
            {settings.companyChapter.creditModal.p2}
          </Typography>
          <Typography
            color="textPrimary"
            align="center"
            variant="body2"
            className={classes.paragraph}
          >
            {settings.companyChapter.creditModal.p3}
          </Typography>
          <Divider />
          <Typography
            align="center"
            variant="h6"
            component="h2"
          >
            {settings.companyChapter.creditModal.secondHeader}
          </Typography>
          <Typography
            color="textPrimary"
            align="center"
            variant="body2"
            className={classes.paragraph}
          >
            {settings.companyChapter.creditModal.p4}
          </Typography>
          <Typography
            color="textPrimary"
            align="center"
            variant="body2"
            className={classes.paragraph}
          >
            {settings.companyChapter.creditModal.p5}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default React.memo(CreditTerms)
