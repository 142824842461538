import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import { settings } from '../../resources'
import { CompanyLookup } from '../../application/types'
import IconTextInput from './IconTextInput'
import { useSignup } from '../../signupContext'

interface Props {
  fetching: boolean
  setError: (hasError: boolean) => void,
  setCompanyLookup: (companyLookup: CompanyLookup) => void,
}

interface Form {
  companyName: string
  cin: string
}

const illegalCharacters = new RegExp('^[^\\[\\]<>{}\\\\()`;]*$')
const hasIllegalCharacters = (str: string) => !illegalCharacters.test(str)

const validators: { [key in keyof Form]: (values: Form) => string } = {
  companyName: (values: Form) => {
    const { companyName } = values
    if (companyName.length >= 3 && companyName.length <= 64 && !hasIllegalCharacters(companyName)) {
      return ''
    }
    return settings.commonErrors.companyNameError
  },
  cin: (values: Form) => {
    const { cin } = values
    if (cin.length < 20 && cin.length > 3 && !hasIllegalCharacters(cin)) {
      return ''
    }
    return settings.commonErrors.cinError
  },
}

const CompanyManualInput = (props: Props) => {
  const { fetching, setError, setCompanyLookup } = props
  const signup = useSignup()

  const [values, setValues] = useState<Form>({
    companyName: signup.data?.company?.companyName ?? '',
    cin: '',
  })
  const [errors, setErrors] = useState<Form>({
    companyName: '',
    cin: '',
  })

  function onChange(evt: React.ChangeEvent<HTMLInputElement> | { target: { name: string, value: string } }) {
    const { name: _name, value }: { name: string, value: string } = evt.target
    const name = _name as keyof Form

    const newValues = { ...values, [name]: value }

    setValues(newValues)
    setErrors({ ...errors, [name]: validators[name](newValues) })

    const hasError = !!Object.values(validators).find(validator => validator(newValues))
    setError(hasError)

    if (!hasError) {
      setCompanyLookup(newValues)
    }
  }

  useEffect(() => {
    if (signup.data.company?.companyName) {
      onChange({
        target: {
          name: 'companyName',
          value: signup.data.company?.companyName as string
        }
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signup.data.company?.companyName])

  return (
    <Grid container alignItems="flex-end" style={{ flexDirection: "column", flex: 1, marginTop: 20, marginBottom: 20, alignItems: 'start' }} spacing={3}>
      <IconTextInput
        style={{ width: '100%' }}
        validationError={errors.companyName}
        value={values.companyName}
        onChange={onChange}
        disabled={fetching}
        name="companyName"
        label={settings.commonTerms.companyName}
        testId="companyNameInput"
        autoFocus={true}
        left
      />
      <IconTextInput
        style={{ width: '100%' }}
        validationError={errors.cin}
        value={values.cin}
        onChange={onChange}
        disabled={fetching}
        name="cin"
        label={settings.companyChapter.manualInput.cin}
        testId="manualCinInput"
        right
      />
    </Grid>
  )
}

export default CompanyManualInput
