import { createMuiTheme } from '@material-ui/core/styles'
import createPalette from '@material-ui/core/styles/createPalette'

const theme = createMuiTheme({
  palette: createPalette({
    primary: {
      main: '#f15a24',
      contrastText: '#000'
    },
  }),
  shape: {
    borderRadius: 11,
  },
  typography: {
    fontFamily: 'Comfortaa',
    button: {
      fontSize: '1em',
      textTransform: 'none',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        padding: "8px 0",
        minWidth: '250px',
      },
    },
    MuiInputLabel: {
      root: {
        textAlign: 'center',
        width: '100%',
      },
      shrink: {
        textAlign: 'left',
      },
    },
  },
})

export default theme
