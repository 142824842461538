// import { ThemeOptions } from "@material-ui/core/styles/createMuiTheme"

export default `{
  "palette": {
    "primary": {
      "main": "#000"
    },
    "secondary": {
      "main": "MAINBUTTONCOLOR",
      "contrastText": "MAINTEXTCOLOR"
    }
  },
  "theme": {
    "shape": {
      "borderRadius": 11
    },
    "typography": {
      "fontFamily": "Comfortaa",
      "button": {
        "fontSize": "1em",
        "textTransform": "none"
      }
    },
    "overrides": {
      "MuiButton": {
        "contained": {
          "&:disabled": {
            "backgroundColor": "DISABLEDBUTTONCOLOR",
            "color": "DISABLEDTEXTCOLOR"
          }
        },
        "root": {
          "padding": "8px 0",
          "minWidth": "250px"
        }
      },
      "MuiInputLabel": {
        "root": {
          "textAlign": "center",
          "width": "100%"
        },
        "shrink": {
          "textAlign": "left"
        }
      }
    }
  }
}`
