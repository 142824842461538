import React, { useContext, useState } from 'react'
import { CompanyAddress, Company, UserBlock, MerchantUserErrors, SignupSession, CompanyLookup } from './application/types'

interface SignupProviderT {
  data: SignupState
  actions: {
    resetCompanyData: () => void
    setApprovedCin: (cin: string) => void
    setCompany: (company?: Company) => void
    setPossibleCompanies: (possibleCompanies : Company[]) => void
    setMoreResultsAvaliable:(moreAvailable : boolean) => void
    setTooManyCompanies: (toomany : boolean) => void
    setCompanyLookup: (companyLookup: CompanyLookup) => void
    resetCompanyLookup: () => void
    setMerchantUserErrors: (merchantUserErrors: MerchantUserErrors[]) => void
    setSelectedCompanyAddress: (address: CompanyAddress | undefined) => void
    setSelectedShippingAddress: (address: CompanyAddress | undefined) => void
    setSelectedPostalAddress: (address: CompanyAddress | undefined) => void
    setSession: (session: SignupSession) => void
    setToken: (token: string) => void
    setUser: (user: UserBlock) => void
    setAdditionalInformation: (additionalData: Record<string, unknown>) => void
    setExternalAuthModal: (external_auth: string, validateSignatories: boolean) => void
    setTermsAccepted: (termsAccepted: boolean) => void
    setNewsletter: (newsletter: boolean) => void
  }
}

const context = React.createContext<SignupProviderT | null>(null)

const initialCompanyLookupData: CompanyLookup = {
  cin: '',
  companyName: '',
  zip: '',
  companyAddress: ''
}

interface SignupState {
  approvedCin: string
  company?: Company
  possibleCompanies?: Company[]
  tooManyCompanies?: boolean
  moreAvailable?: boolean
  companyLookup: CompanyLookup
  merchantUserErrors: MerchantUserErrors[]
  selectedCompanyAddress?: CompanyAddress
  session: SignupSession | undefined
  token: string
  url: string
  user?: UserBlock
  additionalInformation?: Record<string, unknown>
  external_auth: string
  validateSignatories: boolean
  termsAccepted?: boolean
  newsletter?: boolean
  selectedShippingAddress?: CompanyAddress
  selectedPostalAddress?: CompanyAddress
}

const initialState: SignupState = {
  approvedCin: '',
  company: undefined,
  companyLookup: initialCompanyLookupData,
  tooManyCompanies: false,
  moreAvailable: false,
  possibleCompanies: undefined,
  merchantUserErrors: [],
  selectedCompanyAddress: undefined,
  session: undefined,
  token: '',
  url: '', // 'https://dev-client.briqpay.com', // While testing
  user: undefined,
  additionalInformation: undefined,
  external_auth: '',
  validateSignatories: false,
  termsAccepted: false,
  newsletter: false,
  selectedShippingAddress: undefined,
  selectedPostalAddress: undefined,
}

const useSignup = (): SignupProviderT => {
  const data = useContext(context)
  if (!data) {
    throw new Error('useSignup must be used within an SignupProvider')
  }
  return data
}

const SignupProvider = ({ ...props }): JSX.Element => {
  const [data, setData] = useState(initialState)

  const actions = React.useMemo(() => ({
    resetCompanyData: () => {
      setData(data => ({
        ...initialState,
        session: data.session,
        user: data.user,
        additionalInformation: data.additionalInformation,
      }))
    },
    setApprovedCin: (approvedCin: string) =>
      setData(data => ({
        ...data,
        approvedCin,
      })),
    setCompany: (company?: Company) =>
      setData(data => ({
        ...data,
        company,
      })),
    setPossibleCompanies : (possibleCompanies : Company[]) => 
      setData(data => ({
        ...data,
        possibleCompanies,
      })),
      setTooManyCompanies: (tooManyCompanies : boolean) => 
      setData(data => ({
        ...data,
        tooManyCompanies,
      })),
      setMoreResultsAvaliable: (moreAvailable : boolean) => 
      setData(data => ({
        ...data,
        moreAvailable,
      })),
    setCompanyLookup: (companyLookup: CompanyLookup) => {
      setData(data => ({
        ...data,
        companyLookup: companyLookup
      }))
    },
    resetCompanyLookup: () => {
      setData(data => ({
        ...data,
        companyLookup: initialCompanyLookupData
      }))
    },
    setMerchantUserErrors: (merchantUserErrors: MerchantUserErrors[]) =>
      setData(data => ({
        ...data,
        merchantUserErrors
      })),
    setSelectedCompanyAddress: (address?: CompanyAddress) =>
      setData(data => ({
        ...data,
        selectedCompanyAddress: address,
      })),
    setSelectedShippingAddress: (address?: CompanyAddress) =>
      setData(data => ({
        ...data,
        selectedShippingAddress: address,
      })),
    setSelectedPostalAddress: (address?: CompanyAddress) =>
      setData(data => ({
        ...data,
        selectedPostalAddress: address,
      })),
    setSession: (session: SignupSession) => setData(data => ({
      ...data,
      session,
    })),
    setToken: (token: string) =>
      setData(data => ({
        ...data,
        token,
      })),
    setUser: (user: UserBlock) =>
      setData(data => ({
        ...data,
        user,
      })),
    setAdditionalInformation: (additionalData: Record<string, unknown>) =>
      setData(data => ({
        ...data,
        additionalInformation: additionalData,
      })),
    setExternalAuthModal: (external_auth: string, validateSignatories: boolean) =>
      setData((data) => ({
        ...data,
        external_auth,
        validateSignatories
      })),
    setTermsAccepted: (termsAccepted: boolean) =>
      setData((data) => ({
        ...data,
        termsAccepted,
      })),
    setNewsletter: (newsletter: boolean) =>
      setData((data) => ({
        ...data,
        newsletter,
      })),
  }), [])

  const signupData = {
    data,
    actions,
  }

  return <context.Provider value={signupData} {...props} />
}

export { SignupProvider, useSignup }
