import React, { FC, useState, useEffect } from 'react'
import QRCode from 'qrcode.react'
import isMobile from 'ismobilejs'

import useAsyncInterval2 from '../../../hooks/useAsyncInterval2'
import { BankIdAuth, BankIdCollect, BankIdOtherEnum, BankIdStatus } from '../../bankid/types'
import RfaMessage from './components/RfaMessage'
import styled from 'styled-components'

const getAutoStartUrl = (autoStartToken: string): string => {
  return `bankid:///?autostarttoken=${autoStartToken}&redirect=null`
}

const Button = styled.button`
  background-color: #469cbe;
  color: white;
  border: 1px solid aliceblue;
  padding: 12px 30px;
  float: left;
  font-weight: bold;
  width: 48%;
  margin-top: 4px;
  text-transform: uppercase;
  font-size: 0.875rem;
  font-family: Comfortaa;
  cursor: pointer;
  border-radius: 8px;
  &:hover {
    background-color: rgb(34, 89, 113);
  }
  &:disabled {
    background-color: #8da8b3;
  }
`

interface Props {
  translations: any
  onSuccess: () => void
  onCancel: () => void
  authRequest: (data: Record<string, unknown>) => Promise<BankIdAuth>
  pollRequest: (id: string) => Promise<BankIdCollect>
  cancelRequest: (id: string) => Promise<any>
}

const BankID: FC<Props> = ({ translations, onSuccess, onCancel, authRequest, pollRequest, cancelRequest }) => {
  const [initLoading, setInitLoading] = useState(false)
  const [showQr, setShowQr] = useState(() => !isMobile(navigator.userAgent).phone)
  const [attemptedToOpenSameDevice, setAttemptedToOpenSameDevice] = useState(false)
  const [providerData, setProviderData] = useState<Partial<BankIdAuth & BankIdCollect> | null>(null)

  // Poll collect
  useAsyncInterval2(
    async () => {
      if (providerData?._id && providerData.status === BankIdStatus.PENDING) {
        pollRequest(providerData._id)
          .then((data) => {
            setProviderData({ ...providerData, ...data })
            if (data.status === BankIdStatus.COMPLETE) {
              onSuccess()
            }
          })
          .catch(() => {
            setProviderData({ ...providerData, status: BankIdStatus.FAILED, hintCode: BankIdOtherEnum.INTERNAL_ERROR })
          })
      }
    },
    [providerData?._id],
    1100
  )

  const startQr = () => {
    setInitLoading(true)
    setAttemptedToOpenSameDevice(false)
    // TODO: Remove data from this request
    // endUserIp should not be sent by client, the server should pick it up
    authRequest({})
      .then((data) => {
        setProviderData({ ...data, status: BankIdStatus.PENDING })
      })
      .catch(() => {
        setProviderData({ ...providerData, status: BankIdStatus.FAILED, hintCode: BankIdOtherEnum.INTERNAL_ERROR })
      })
      .finally(() => {
        setInitLoading(false)
      })
  }

  // Init on load
  useEffect(() => {
    startQr()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onOpenSameDevice = () => {
    setAttemptedToOpenSameDevice(true)
    window.open(getAutoStartUrl(providerData!.autoStartToken!))
  }

  const onCancelPressed = async () => {
    await cancelRequest(providerData!._id!).catch(void 0)
    onCancel()
  }

  const failedSign = providerData?.status === 'failed'
  const loading = initLoading || !providerData?.autoStartToken
  const disabledButton = loading

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyItems: 'center',
        paddingTop: 8,
        minHeight: 600,
        padding: '0.875rem',
      }}
    >
      {/* Logo if the provider */}
      <img
        alt="BankID"
        style={{ height: 160, textAlign: 'center' }}
        src={'https://cdn.briqpay.com/checkout/images/bankid_logo.svg'}
      ></img>

      {/* Info box */}
      <p style={{ marginTop: 0, fontWeight: 'bold', backgroundColor: '#225971', color: 'white', padding: '1rem' }}>
        {translations.authHeader}
      </p>

      {/* Bankid */}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyItems: 'center',
          paddingTop: 8,
          width: '100%',
          height: '100%',
        }}
      >
        {/* BankID QR / show QR button */}
        <div style={{ marginTop: 16, justifyContent: 'center', marginBottom: 12, flexDirection: 'row', width: '100%' }}>
          {showQr ? (
            <QRCode
              value={providerData?.qrToken ?? ''}
              className={providerData?.status === 'pending' ? 'opacity-100' : 'opacity-50'}
            />
          ) : (
            <Button
              onClick={() => setShowQr(true)}
              disabled={failedSign}
              style={{ textAlign: 'center', float: 'none' }}
            >
              {translations.showQr}
            </Button>
          )}
        </div>

        {/* Bankid messages */}
        <RfaMessage
          providerData={providerData}
          translations={translations}
          showingQr={showQr}
          attemptedToOpenSameDevice={attemptedToOpenSameDevice}
        />

        <div
          // className="flex flex-row gap-2 w-full mt-6"
          style={{ display: 'flex', flexDirection: 'row', gap: 8, width: '100%', marginTop: 24 }}
        >
          {/* Cancel button */}
          <Button
            onClick={onCancelPressed}
            disabled={loading}
            style={{
              backgroundColor: '#dddddd',
              color: '#242424',
            }}
          >
            {translations.cancel}
          </Button>

          {/* Same device / restart button */}
          {failedSign ? (
            <Button onClick={startQr} disabled={initLoading}>
              {translations.tryAgain}
            </Button>
          ) : (
            <Button onClick={onOpenSameDevice} disabled={disabledButton}>
              {translations.openBankId}
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}

export default BankID
