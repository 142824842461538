import React, { useEffect, useState } from 'react'
import './App.css'
import { SignupProvider, useSignup } from './signupContext'
import ThemeSwitcher from './components/ThemeSwitcher/ThemeSwitcher'
import CompanyLookupChapter from './components/CompanyChapter/CompanyLookupChapter'
import CompanyAddressDropDown from './components/CompanyChapter/CompanyAddressDropDown'
import SignupError from './components/SignupError/SignupError'
import SignupSuccess from './components/SignupSuccess/SignupSuccess'
import UserEdit from './components/UserChapter/UserEdit'
import UserOverview from './components/UserChapter/UserOverview'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { AppRoutes, CUSTOMER_TYPE } from './application/types'
import Overview from './signup/Overview'
import PoweredByBriqpay from './components/PoweredByBriqpay/PoweredByBriqpay'
import useResizeObserver from 'use-resize-observer'
import NavigationReset from './NavigationReset'
import InitializeApp from './InitializeApp'
import CreditTerms from './components/CompanyChapter/CreditTerms'
import AdditionalInformationEdit from './components/AdditionalInformationChapter/AdditionalInformationEdit'
import { CircularProgress } from '@material-ui/core'

function App() {
  const MIN_HEIGHT = 170
  const OFFSET = 20

  const signup = useSignup()
  const { ref, height = MIN_HEIGHT } = useResizeObserver<HTMLDivElement>()

  const [shouldShowLogo, setShouldShowLogo] = useState(false)

  useEffect(() => {
    const newHeight = height > MIN_HEIGHT ? height : MIN_HEIGHT
    window.parent.postMessage({ messageType: 'height_signup', messageContent: newHeight + OFFSET }, '*')
  }, [height])

  useEffect(() => {
    if (!signup.data.session?.state) {
      return
    }

    const shouldHide =
      signup.data.session?.merchantconfig?.signup_powered_by_override === true ||
      signup.data.session?.merchantconfig?.signup_powered_by_override === signup.data.session?.customerType ||
      signup.data.session?.merchantconfig?.signup_powered_by_override === 'all' ||
      signup.data.session?.customerType === CUSTOMER_TYPE.CONSUMER // Always hide for consumer signups for now

    setShouldShowLogo(!shouldHide)
  }, [
    signup.data.session?.customerType,
    signup.data.session?.state,
    signup.data.session?.merchantconfig?.signup_powered_by_override,
  ])

  return (
    <div ref={ref}>
      <ThemeSwitcher>
        <BrowserRouter>
          <NavigationReset />
          <InitializeApp />

          {!signup.data.session?.country && (
            <div style={{ height: 144 }}>
              <div style={{ marginLeft: 'calc(50% - 15px)', paddingTop: 50 }}>
                <CircularProgress size="30px" />
              </div>
            </div>
          )}
          {!!signup.data?.session && (
            <Routes>
              <Route path={AppRoutes.HOME} element={<Overview />} />
              <Route path={AppRoutes.CHAPTER_COMPANY_COMPANY_LOOKUP} element={<CompanyLookupChapter />} />
              <Route path={AppRoutes.CHAPTER_COMPANY_ADDRESS_EDIT} element={<CompanyAddressDropDown />} />
              <Route path={AppRoutes.CHAPTER_COMPANY_CREDIT_TERMS} element={<CreditTerms />} />
              <Route path={AppRoutes.CHAPTER_USER_EDIT} element={<UserEdit />} />
              <Route path={AppRoutes.CHAPTER_ADDITIONAL_INFORMATION_EDIT} element={<AdditionalInformationEdit />} />
              <Route path={AppRoutes.CHAPTER_USER_OVERVIEW} element={<UserOverview />} />
              <Route path={AppRoutes.SIGNUP_SUCCESS} element={<SignupSuccess />} />
              <Route path={AppRoutes.SIGNUP_ERROR} element={<SignupError />} />
            </Routes>
          )}
        </BrowserRouter>
        {!!shouldShowLogo && (
          <PoweredByBriqpay />
        )}
      </ThemeSwitcher>
    </div>
  )
}

const ProviderApp = () => {
  return (
    <SignupProvider>
      <App />
    </SignupProvider>
  )
}

export default ProviderApp
