import React, { useEffect, useState } from 'react'
import { Grid,MenuItem,InputLabel,Select } from '@material-ui/core'
import { settings } from '../../resources'
import { CompanyLookup } from '../../application/types'
import { useSignup } from '../../signupContext'
import IconTextInput from './IconTextInput'

interface Props {
  fetching: boolean
  setError: (hasError: boolean) => void,
  setCompanyLookup: (companyLookup: CompanyLookup) => void,
}

const illegalCharacters = new RegExp('^[^\\[\\]<>{}\\\\()`;]*$')
const hasIllegalCharacters = (str: string) => !illegalCharacters.test(str)

const validators: { [key in keyof CompanyLookup]: (values: CompanyLookup) => string } = {
  companyName: (values: CompanyLookup) => {
    const { companyName } = values
    if (companyName && companyName.length >= 3 && companyName?.length <= 64 && !hasIllegalCharacters(companyName)) {
      return ''
    }
    return settings.commonErrors.companyNameError
  },
  zip: (values: CompanyLookup) => {
    const { zip } = values
    if (zip && zip.length >= 4 && zip.length <= 8 && !hasIllegalCharacters(zip)) {
      return ''
    }
    return settings.commonErrors.zipError
  },
  cin: (values: CompanyLookup) => {
    const { cin } = values
    if (cin && !hasIllegalCharacters(cin)) {
      return ''
    }
    return settings.commonErrors.cinError
  },
  companyAddress: (values: CompanyLookup) => {
    const { companyAddress } = values
    if(companyAddress === '' || (companyAddress && companyAddress.length <= 100 && !hasIllegalCharacters(companyAddress))) {
      return ''
    }
    return settings.commonErrors.addressError
  }
}

const CompanyNameZipEdit = (props: Props) => {
  const signup = useSignup()
  const { fetching, setError, setCompanyLookup } = props

  const [values, setValues] = useState<CompanyLookup>({
    companyName: '',
    zip: '',
    companyAddress:  ''
  })
  const [errors, setErrors] = useState<CompanyLookup>({
    companyName: '',
    zip: '',
    companyAddress:  ''
  })
  const [activeFields , setActiveFields] = useState<Partial<Record<keyof CompanyLookup, boolean>>>({
    companyName: true,
    zip: true,
    companyAddress: false,
    cin: false,
  })

  useEffect(() => {
    setActiveFields((af) => ({ ...af, companyAddress: !!(signup.data.tooManyCompanies || signup.data.moreAvailable) }))
  }, [signup.data.tooManyCompanies, signup.data.moreAvailable])

  function onChange(field: keyof CompanyLookup, value: string) {
    signup.actions.setPossibleCompanies([])
    signup.actions.setCompany(undefined)

    const newValues = { ...values, companyKey: undefined, [field]: value }
    setValues(newValues)
    
    if (!value?.length) {
      setErrors({ ...errors, [field]: '' })
    }
    else {
      setErrors({ ...errors, [field]: validators?.[field]?.(newValues) })
    }

    const hasError = Object.entries(activeFields).filter(([_, active]) => active).reduce((acc, [cur]) => {
      const validator = validators[cur as keyof CompanyLookup]
      return  acc || !!validator?.(newValues)
    }, false)
    setError(hasError)

    if (!hasError) {
      setCompanyLookup(newValues)
    }
  }

  function onChangeCompanyKey(event:React.ChangeEvent<{ name?: string; value: unknown }>) {
    if (event.target.value) {
      const stringValue = String(event.target.value)
      const newValues = { ...values, companyKey: stringValue }
      setValues(newValues)

      const selectedCompany = signup?.data?.possibleCompanies?.find(e => e.companyKey === stringValue)
      if (selectedCompany) {
        signup.actions.setCompany(selectedCompany)
        setCompanyLookup({
          cin: selectedCompany.companyKey,
          externalProviderId: selectedCompany.externalProviderId,
        })
        setError(false)
      }
    }
  }

  return (
    <Grid container alignItems="flex-end" style={{ flex: 1, marginTop: 20, marginBottom: 20, alignItems: 'start' }} spacing={3}>
      <IconTextInput
        style={{ flex: 2 }}
        validationError={errors.companyName}
        value={values.companyName}
        onChange={e => onChange('companyName', e.target.value)}
        disabled={fetching}
        name={`companyName_${signup.data.session?.country.toLowerCase()}`}
        label={settings.commonTerms.companyName}
        testId="companyNameInput"
        autoFocus={true}
        left
      />
      <IconTextInput
        style={{ flex: 1 }}
        validationError={errors.zip}
        value={values.zip}
        onChange={e => onChange('zip', e.target.value)}
        disabled={fetching}
        name={`zip${signup.data.session?.country.toLowerCase()}`}
        label={settings.commonTerms.postalCode}
        testId="companyZipInput"
        right
      />
      {!!activeFields?.companyAddress && (
        <Grid container>
          <IconTextInput
            style={{ flex: 1 }}
            validationError={errors.companyAddress}
            value={values.companyAddress}
            onChange={e => onChange('companyAddress', e.target.value)}
            disabled={fetching}
            name={`companyAddress_${signup.data.session?.country.toLowerCase()}`}
            label={settings.commonTerms.address}
            testId="companyAddressInput"
            right
          />
        </Grid>
      )}
      {!!signup.data.possibleCompanies?.length && (
        <Grid container>
          <InputLabel style={{  marginTop: '2rem' }} id="companyIdentifierSelector">
            {settings.companyChapter.nameZipEdit.selectCompanyEntity}
          </InputLabel>
          <Select
            labelId="companyIdentifierSelector"
            value={values.cin}
            label="CompanyIdentifier"
            name="companyKey"
            onChange={onChangeCompanyKey}
            style={{ width: '100%', margin: '0.5rem' }}
          >
            {signup.data.possibleCompanies.map((e) => (
              <MenuItem key={e.companyKey} value={e.companyKey}>
                {e.companyKey === 'manual' ? 
                  settings.companyChapter.nameZipEdit.cantFindCompany : 
                  `${e.companyKey} - ${e.companyName}`
                }
              </MenuItem>
            ))}
          </Select>
          {signup.data.moreAvailable && <span>{settings.companyChapter.nameZipEdit.moreCompaniesAreAvailable}</span>}
        </Grid>
      )}
    </Grid>
  )
}

export default CompanyNameZipEdit
