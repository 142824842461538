import johnny from '../johnny'
import settings, { load } from './settings'

const loadResources = async () => {
  const locale = johnny.getLocale().toLowerCase()
  try {
    await load(locale)
  } catch (e) {
    await load('sv-se')
  }
}

export { settings, loadResources }
