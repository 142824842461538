import React, { FC, useEffect, useState } from 'react'
import { useBankId } from './Context'
import { BankIdStatus } from './types'
import QRCode from 'qrcode.react'
import { Button } from '@material-ui/core'
import RfaMessage from './RfaMessage'
import { settings } from '../../resources'

interface Props {
  loading: boolean
}

const Qr: FC<Props> = ({ loading }) => {
  const { startState, collectState, resetShallow } = useBankId()

  const [qrCode, setQrCode] = useState<string>('')

  useEffect(() => {
    const newQrCode = collectState?.qrToken || startState?.qrToken
    if (newQrCode) {
      setQrCode(newQrCode)
    }
  }, [collectState?.qrToken, startState?.qrToken])

  const onReset = () => {
    setQrCode('')
    resetShallow()
  }

  return (
    <div
      style={{
        alignSelf: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 20,
        maxWidth: 480
      }}
    >
      <RfaMessage />
      <div style={{ overflow: 'hidden', borderRadius: 6, height: 128, width: 128 }}>
        <QRCode
          value={qrCode}
          style={{ opacity: !qrCode || loading || (collectState && collectState?.status !== BankIdStatus.PENDING) ? 0.3 : 1.0 }}
        />
      </div>
      {(collectState?.status === BankIdStatus.FAILED) && (
        <Button variant="outlined" onClick={onReset}>
          {settings.bankid.restartQr}
        </Button>
      )}
    </div>
  )
}

export default Qr
