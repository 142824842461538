import React, { FC } from 'react'
import { CompanyLookup } from '../../application/types'
import { settings } from '../../resources'
import BankIdWrapper from '../bankid/BankIdWrapper'
import { BankIdCollect } from '../bankid/types'

interface Props {
  submitData: (lookupData: CompanyLookup) => Promise<void>
}

const BankIDLookup: FC<Props> = ({ submitData }) => {
  const onSuccess = async (collectState: BankIdCollect) => {
    return submitData({ pno: collectState.completionData.user.personalNumber })
  }

  return (
    <div>
      <BankIdWrapper translations={settings.bankid} onSuccess={onSuccess} />
    </div>
  )
}

export default BankIDLookup
