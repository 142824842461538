export interface Company {
  companyKey: string
  companyName: string
  address?: CompanyAddress[]
  externalProviderId?: string
}

export interface CompanyAddress {
  streetaddress: string
  zip: string
  city: string
  hq: boolean
  _id: string
  manualInput?: boolean
}
export interface IncorrectFields {
  firstName: string
  lastName: string
  email: string
  phone: string
  cin: string
}
export interface UserBlock {
  firstName: string
  lastName: string
  email: string
  phone: string
}

export enum BriqErrorCode {
  UNKNOWN = 'UNKNOWN',
  COMPANY_REJECTED = 'company_rejected',
  WEBHOOK_FAIL = 'webhook_fail',
  WEBHOOK_TIMEOUT = 'webhook_timeout',
}

export enum AppRoutes {
  HOME = '/',
  CHAPTER_COMPANY_COMPANY_LOOKUP = '/chapter/company/lookup/edit',
  CHAPTER_COMPANY_ADDRESS_EDIT = '/chapter/company/address/edit',
  CHAPTER_COMPANY_CREDIT_TERMS = '/chapter/company/creditterms',
  CHAPTER_USER_EDIT = '/chapter/user/edit',
  CHAPTER_USER_OVERVIEW = '/chapter/user/overview',
  CHAPTER_ADDITIONAL_INFORMATION_EDIT = '/chapter/additionalinformation/edit',
  SIGNUP_SUCCESS = '/signup/success',
  SIGNUP_ERROR = '/signup/error',
  CHAPTER_COMPANY_ADDRESS_MANUAL = '/chapter/company/address/manual',
}

export enum VALIDATION_FLOW {
  WEBHOOK = 'webhook',
  CLIENT_API = 'client_api',
  NONE = 'none',
}

export enum SIGNUP_SESSION_STATE {
  // generic states
  SESSION_CREATED = 'session_created',
  COMPANY_APPROVED = 'company_approved',
  BRIQPAY_VALIDATED = 'briqpay_validated',
  COMPLETED = 'completed',

  // webhook validation flow
  MERCHANT_HOOK_PENDING = 'merchant_hook_pending',
  MERCHANT_HOOK_REJECTED = 'merchant_hook_rejected',
  MERCHANT_HOOK_REVALIDATE = 'merchant_hook_revalidate',

  // client/postmessage validation flow
  MERCHANT_CLIENTAPI_PENDING_APPROVAL = 'merchant_clientapi_pending_approval',
}

export enum SIGNUP_RESULT {
  SUCCESS,
  INVALID_USER_FIELDS,
  UNKNOWN_ERROR,
  TIMEOUT,
  INVALID_COMPANY,
}

export enum TERMS_COUNTRIES {
  SE = 'se',
}

export enum CUSTOMER_TYPE {
  BUSINESS = 'business',
  CONSUMER = 'consumer',
}

export enum VALIDATION_ERROR_CODE {
  ALREADY_EXISTS = 'already_exists',
  INVALID = 'invalid',
}

export interface MerchantUserErrors {
  errorCode: VALIDATION_ERROR_CODE
  errorMessage: string
  errorField: keyof IncorrectFields
}

export interface ValidationResponse {
  errors: MerchantUserErrors[]
  state: SIGNUP_SESSION_STATE
}

export enum AdditionalInformationCustomFieldType {
  TEXT = 'text',
  NUMBER = 'number',
  EMAIL = 'email',
  PHONE = 'phone',
  SELECT = 'select',
  RADIO = 'radio',
  SWITCH = 'switch',
}

export interface AdditionalInformationCustomFieldBase {
  fieldName: string
  required: boolean
  label: string
  showInOverview?: boolean
  showLabelInOverview?: boolean
  showIfTrue?: string
  showIfFalse?: string
}

export interface AdditionalInformationCustomFieldText extends AdditionalInformationCustomFieldBase {
  type: AdditionalInformationCustomFieldType.TEXT
}

export interface AdditionalInformationCustomFieldNumber extends AdditionalInformationCustomFieldBase {
  type: AdditionalInformationCustomFieldType.NUMBER
}

export interface AdditionalInformationCustomFieldEmail extends AdditionalInformationCustomFieldBase {
  type: AdditionalInformationCustomFieldType.EMAIL
}

export interface AdditionalInformationCustomFieldPhone extends AdditionalInformationCustomFieldBase {
  type: AdditionalInformationCustomFieldType.PHONE
}

export interface AdditionalInformationCustomFieldSelect extends AdditionalInformationCustomFieldBase {
  type: AdditionalInformationCustomFieldType.SELECT
  values: string[]
  emptyDropdownValue?: string
  trueIfValueMatch?: string
}

export interface AdditionalInformationCustomFieldRadio extends AdditionalInformationCustomFieldBase {
  type: AdditionalInformationCustomFieldType.RADIO
  values: string[]
}

export interface AdditionalInformationCustomFieldSwitch extends AdditionalInformationCustomFieldBase {
  type: AdditionalInformationCustomFieldType.SWITCH
}

export type AdditionalInformationCustomField =
  | AdditionalInformationCustomFieldText
  | AdditionalInformationCustomFieldNumber
  | AdditionalInformationCustomFieldEmail
  | AdditionalInformationCustomFieldPhone
  | AdditionalInformationCustomFieldSelect
  | AdditionalInformationCustomFieldRadio
  | AdditionalInformationCustomFieldSwitch

export type AdditionalInformationRow = AdditionalInformationCustomField[]

export interface AdditionalInformationGroup {
  header: string
  rows: AdditionalInformationRow[]
}

export interface AdditionalInformationConfig {
  header: string
  groups: AdditionalInformationGroup[]
}

export interface SignupSession {
  state: SIGNUP_SESSION_STATE
  customerType?: CUSTOMER_TYPE
  merchanturls: {
    terms: string
    signupwebhook: string
    redirecturl: string
  }
  country: string
  validationFlow: VALIDATION_FLOW
  merchantconfig?: {
    signup_enabled_customer_types?: CUSTOMER_TYPE[]
    signup_allowed_manual_addresses?: string[]
    signup_disallow_manual_after_failures?: boolean
    clientPurchaseApprovalTimeout?: number
    signup_enable_reset_company_button?: boolean
    signupfinalButtonUseContinueText?: boolean
    signup_colors: {
      button: string
      buttonDisabled: string
      buttonText: string
      buttonTextDisabled: string
    }
    signup_custom_fields: AdditionalInformationConfig
    signup_custom_fields_b2c: AdditionalInformationConfig
    signup_remove_first_and_lastname: boolean
    signup_email_confirm_field: boolean
    signup_terms: {
      enabled: boolean
      url: string
      text: string
      linkText: string
    }
    signup_newsletter: {
      enabled: boolean
      url: string
      text: string
      linkText: string
    }
    signup_postal_address: {
      enabled: boolean
    }
    signup_shipping_address: {
      enabled: boolean
    }
    signup_success_text: string
    signup_failure_text: string
    signup_font?: {
      name: string
      link: string
    }
    signup_powered_by_override?: boolean | 'all' | CUSTOMER_TYPE
    disableCreditText?: boolean
    disableCreditPop?: boolean
  }
}

export interface CompanyLookup {
  cin?: string
  vatNo?: string
  companyName?: string
  zip?: string
  companyAddress?: string
  externalProviderId?: string
  pno?: string
}
