import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { AppRoutes } from '../application/types'

function useNavigateReplace() {
  const navigate = useNavigate()

  return useCallback((route: AppRoutes) => {
    if (process.env.NODE_ENV === 'development') {
      console.log('(DEV) Navigating to', route)
    }
    
    window.parent.postMessage({ messageType: 'event', eventName: "navigation", messageContent: { route:  route}}, '*')
    navigate(route + window.location.search, { replace: true })
  }, [navigate])
}

export default useNavigateReplace
