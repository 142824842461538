export enum BankIdStatus {
  PENDING = 'pending',
  FAILED = 'failed',
  COMPLETE = 'complete',
}

export enum BankIdFailedHintCode {
  EXPIRED_TRANSACTION = 'expiredTransaction',
  CERTIFICATE_ERR = 'certificateErr',
  USER_CANCEL = 'userCancel',
  CANCELLED = 'cancelled',
  START_FAILED = 'startFailed',
  BRIQ_INCORRECTSIGNATORY = 'briqIncorrectSignatory',
}

export enum BankIdPendingHintCode {
  OUTSTANDING_TRANSACTION = 'outstandingTransaction',
  NO_CLIENT = 'noClient',
  STARTED = 'started',
  USER_SIGN = 'userSign',
}

export enum BankIdOtherEnum {
  ALREADY_IN_PROGRESS = 'alreadyInProgress',
  REQUEST_TIMEOUT = 'requestTimeout',
  MAINTENANCE = 'maintenance',
  INTERNAL_ERROR = 'internalError',
}

export type BankIdHintCode = BankIdFailedHintCode | BankIdPendingHintCode | BankIdOtherEnum

export enum BankIdType {
  AUTH = 'auth',
  SIGN = 'sign',
}

export interface BankIdStartParams {
  endUserIp: string
  personalNumber?: string
  allowFingerprint?: boolean
  userVisibleText?: string
}

export interface BankIdAuth {
  autoStartToken: string
  _id: string
  qrToken: string
}

export interface BankIdCollect {
  status: BankIdStatus
  hintCode: BankIdFailedHintCode | BankIdPendingHintCode | BankIdOtherEnum
  qrToken: string
  completionData: {
    user: {
      personalNumber: string
      name: string
      givenName: string
      surname: string
    }
    device: { ipAddress: string }
    cert: {
      notBefore: string
      notAfter: string
    }
    signature: string
    ocspResponse: string
  }
}

export interface Rfa {
  text: string
  status: BankIdStatus
}
