import React, { FC, useEffect, useState } from 'react'
import { useSignup } from '../../signupContext'
import { AppRoutes, CUSTOMER_TYPE, Company, CompanyAddress, CompanyLookup, TERMS_COUNTRIES, UserBlock } from '../../application/types'

import { Button, Grid, LinearProgress, Typography, Box } from '@material-ui/core'
import { settings } from '../../resources'
import fetchr from '../../application/fetchr'
import useNavigateReplace from '../../hooks/useNavigateReplace'
import CinEdit from './CinEdit'
import VatNoEdit from './VatNoEdit'
import CompanyNameZipEdit from './CompanyNameZipEdit'
import CompanyManualInput from './CompanyManualInput'
import B2Switcher from './CustomerTypeSwitcher'
import BankIDLookup from './BankIDLookup'

enum LOOKUP_METHOD {
  CIN = 'cin',
  VATNO = 'vatNo',
  SEARCH = 'search',
  MANUAL = 'manual',
  BANKID = 'bankid'
}

export enum LOOKUP_ERROR_CODE {
  UNKNOWN = 'UNKNOWN',
  DATABASE_ERROR = 'DATABASE_ERROR',
  TOO_MANY_COMPANIES = 'too_many_companies_found',
  TOO_MANY_COMPANIES_ON_LOOKUP = 'too_many_companies_found_on_lookup',
  INVALID_QUERY = 'invalid_query',
  NONE_FOUND = 'none_found',
  NONE_FOUND_BE_MORE_SPECIFIC = 'none_found_be_more_specific',
}

interface LookupForm {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: FC<any>,
  url: string,
  noSubmitButton?: boolean
}

interface LookupCountry {
  lookupMethods: LOOKUP_METHOD[]
}

const b2bLookupCountries: Record<string, LookupCountry> = {
  se: {
    lookupMethods: [LOOKUP_METHOD.CIN, LOOKUP_METHOD.MANUAL]
  },
  dk: {
    lookupMethods: [LOOKUP_METHOD.CIN, LOOKUP_METHOD.MANUAL]
  },
  fi: {
    lookupMethods: [LOOKUP_METHOD.CIN, LOOKUP_METHOD.MANUAL]
  },
  no: {
    lookupMethods: [LOOKUP_METHOD.CIN, LOOKUP_METHOD.MANUAL]
  },
  nl: {
    lookupMethods: [LOOKUP_METHOD.CIN, LOOKUP_METHOD.MANUAL]
  },
  gb: {
    lookupMethods: [LOOKUP_METHOD.SEARCH, LOOKUP_METHOD.CIN, LOOKUP_METHOD.MANUAL]
  },
  fr: {
    lookupMethods: [LOOKUP_METHOD.SEARCH, LOOKUP_METHOD.CIN, LOOKUP_METHOD.MANUAL]
  },
  es: {
    lookupMethods: [LOOKUP_METHOD.SEARCH, LOOKUP_METHOD.CIN, LOOKUP_METHOD.MANUAL]
  },
  ch: {
    lookupMethods: [LOOKUP_METHOD.SEARCH, LOOKUP_METHOD.CIN, LOOKUP_METHOD.MANUAL]
  },
  it: {
    lookupMethods: [LOOKUP_METHOD.VATNO, LOOKUP_METHOD.SEARCH, LOOKUP_METHOD.MANUAL]
  },
  ie: {
    lookupMethods: [LOOKUP_METHOD.SEARCH, LOOKUP_METHOD.MANUAL]
  },
  at: {
    lookupMethods: [LOOKUP_METHOD.SEARCH, LOOKUP_METHOD.MANUAL]
  },
  de: {
    lookupMethods: [LOOKUP_METHOD.SEARCH, LOOKUP_METHOD.MANUAL]
  },
  pt: {
    lookupMethods: [LOOKUP_METHOD.MANUAL]
  },
  nz: {
    lookupMethods: [LOOKUP_METHOD.MANUAL]
  },
  au: {
    lookupMethods: [LOOKUP_METHOD.MANUAL]
  },
  ae: {
    lookupMethods: [LOOKUP_METHOD.MANUAL]
  },
  sa: {
    lookupMethods: [LOOKUP_METHOD.MANUAL]
  },
  global:{
    lookupMethods: [LOOKUP_METHOD.MANUAL]
  }
}

const b2cLookupCountries: Record<string, LookupCountry> = {
  se: {
    lookupMethods: [LOOKUP_METHOD.BANKID]
  },
}

const LOOKUP_FORMS: Record<LOOKUP_METHOD, LookupForm> = {
  [LOOKUP_METHOD.CIN]: {
    component: CinEdit,
    url: '/api/signup/lookup-company'
  },
  [LOOKUP_METHOD.VATNO]: {
    component: VatNoEdit,
    url: '/api/signup/lookup-company'
  },
  [LOOKUP_METHOD.SEARCH]: {
    component: CompanyNameZipEdit,
    url: '/api/signup/search-company'
  },
  [LOOKUP_METHOD.MANUAL]: {
    component: CompanyManualInput,
    url: '/api/signup/submit-manual-input'
  },
  [LOOKUP_METHOD.BANKID]: {
    component: BankIDLookup,
    url: '/api/signup/lookup-consumer',
    noSubmitButton: true
  }
}

const CompanyLookupChapter = () => {
  const signup = useSignup()
  const navigate = useNavigateReplace()
  const [hasFormError, setHasFormError] = useState(true)
  const [genericError, setGenericError] = useState('')
  const [fetching, setFetching] = useState(false)
  const [shouldShowTerms, setShouldShowTerms] = useState(false)

  const lookupCountry =
    signup.data.session?.customerType === CUSTOMER_TYPE.CONSUMER
      ? b2cLookupCountries[signup.data.session?.country.toLowerCase() as string] ?? { lookupMethods: [LOOKUP_METHOD.MANUAL] }
      : b2bLookupCountries[signup.data.session?.country.toLowerCase() as string] ?? { lookupMethods: [LOOKUP_METHOD.MANUAL] }

  const supportedLookupMethods = lookupCountry.lookupMethods

  const [previousLookupMethod, setPreviousLookupMethod] = useState(() => supportedLookupMethods[0])
  const [lookupMethod, setLookupMethod] = useState(() => supportedLookupMethods[0])
  const [failureCount, setFailureCount] = useState(0)

  // Set the country in a state in order to have a cleaner JSX structure
  useEffect(() => {
    if (Object.values(TERMS_COUNTRIES).includes((signup.data.session?.country || '').toLowerCase() as TERMS_COUNTRIES)) {
      setShouldShowTerms(true)
    } else {
      setShouldShowTerms(false)
    }
  }, [signup.data.session])

  useEffect(() => {
    setPreviousLookupMethod(supportedLookupMethods[0])
    setLookupMethod(supportedLookupMethods[0])
    setGenericError('')
    setHasFormError(true)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signup.data.session?.customerType])

  useEffect(() => {
    signup.data.merchantUserErrors.map((merchantError) => {

      switch (merchantError.errorField) {
        case 'cin':
          setGenericError(merchantError.errorMessage)
          break
      }
    })
  }, [signup.data.merchantUserErrors])



  const lookupForm = LOOKUP_FORMS[lookupMethod]

  const submitData = async (lookupData: CompanyLookup) => {
    if (signup.data.company?.companyKey?.toLowerCase() === 'manual' && lookupMethod !== LOOKUP_METHOD.MANUAL) {
      setPreviousLookupMethod(lookupMethod)
      setLookupMethod(LOOKUP_METHOD.MANUAL)
      return
    }

    try {
      setFetching(true)
      signup.actions.setCompanyLookup(lookupData)
      const data = await fetchr(signup.data.url + lookupForm.url, signup.data.token)
        .post({
          lookupData,
        })
        .then(res => res.json())
        .catch(async (error) => {
          setFailureCount(count => count + 1)
          switch (error.status) {
            case 403: {
              navigate(AppRoutes.SIGNUP_ERROR)
              break
            }

            case 400: {
              const code = await error.json().then((errorBody: { code: string }) => errorBody.code)

              signup.actions.setTooManyCompanies(code === LOOKUP_ERROR_CODE.TOO_MANY_COMPANIES)

              switch (code) {
                case LOOKUP_ERROR_CODE.NONE_FOUND:
                  if (signup.data.session?.customerType === CUSTOMER_TYPE.CONSUMER) {
                    setGenericError(settings.companyChapter.cinEdit.errors.somethingWentWrong)
                  } else {
                    setGenericError(settings.companyChapter.nameZipEdit.noCompanyFound)
                  }
                  break

                case LOOKUP_ERROR_CODE.TOO_MANY_COMPANIES:
                  setGenericError(settings.companyChapter.nameZipEdit.tooManyCompaniesFound)
                  break

                case LOOKUP_ERROR_CODE.TOO_MANY_COMPANIES_ON_LOOKUP:
                  setGenericError(settings.companyChapter.nameZipEdit.tooManyCompaniesFoundOnLookup)
                  break

                case LOOKUP_ERROR_CODE.NONE_FOUND_BE_MORE_SPECIFIC:
                  setGenericError(settings.companyChapter.nameZipEdit.noCompanyFoundBeMoreSpecific)
                  break

                default:
                  setGenericError(settings.companyChapter.cinEdit.errors.somethingWentWrong)
                  break
              }
              break
            }

            case 500:
            default:
              setGenericError(settings.companyChapter.cinEdit.errors.somethingWentWrong)
              break
          }
        })
        .finally(() => setFetching(false))

      const company: Company | undefined = data?.company
      const possibleCompanies: Company[] | undefined = data?.companies
      const address: CompanyAddress | undefined = data?.address
      const user: UserBlock | undefined = data?.user

      if (address) {
        signup.actions.setSelectedCompanyAddress(address)
      }

      if (user) {
        signup.actions.setUser(user)
      }

      if (company) {
        signup.actions.setApprovedCin(company.companyKey)
        signup.actions.setCompany(company)
        if (company.address?.length === 1) {
          signup.actions.setSelectedCompanyAddress(company.address[0])
          if (signup.data.session?.merchantconfig?.signup_shipping_address?.enabled) {
            signup.actions.setSelectedShippingAddress(company.address[0])
          }
          if (signup.data.session?.merchantconfig?.signup_postal_address?.enabled) {
            signup.actions.setSelectedPostalAddress(company.address[0])
          }
        }
        const destination = company.address?.length === 1 ? AppRoutes.CHAPTER_USER_EDIT : AppRoutes.CHAPTER_COMPANY_ADDRESS_EDIT
        navigate(destination)
      }

      if (possibleCompanies) {
        signup.actions.setMoreResultsAvaliable(!!data.moreResultsAvaliable)
        signup.actions.setPossibleCompanies([ ...possibleCompanies, { companyKey: 'manual', companyName: lookupData?.companyName as string }])
      }
    } catch (error) {
      setGenericError(settings.companyChapter.cinEdit.errors.somethingWentWrong)
    }
  }

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement | HTMLButtonElement | MouseEvent>) => {
    e.preventDefault()

    return submitData(signup.data.companyLookup)
  }

  const handleSetFormError = (hasError: boolean) => {
    setHasFormError(hasError)
    if (!hasError) {
      setGenericError('')
    }
  }

  const switchLookupMethod = (lm: LOOKUP_METHOD) => {
    signup.actions.resetCompanyLookup()
    signup.actions.setMoreResultsAvaliable(false)
    signup.actions.setPossibleCompanies([])
    setGenericError('')
    setPreviousLookupMethod(lookupMethod)
    setLookupMethod(lm)

    if (lm !== LOOKUP_METHOD.MANUAL) {
      setFailureCount(0)
    }
  }

  const enabledSignupCustomerTypes = signup.data.session?.merchantconfig?.signup_enabled_customer_types ?? [CUSTOMER_TYPE.BUSINESS]

  return (
    <form onSubmit={handleFormSubmit}>
      <Grid container direction="column" spacing={3}>
        {enabledSignupCustomerTypes?.length > 1 &&
          <Grid item>
            <B2Switcher />
          </Grid>
        }
        <Grid item>
          <lookupForm.component
            fetching={fetching}
            setError={handleSetFormError}
            setCompanyLookup={signup.actions.setCompanyLookup}
            submitData={submitData}
          />
          {(fetching || !!genericError) && (
            <div style={{ minHeight: 16, alignItems: 'center', textAlign: 'center', marginTop: 12 }}>
              {fetching &&  !lookupForm.noSubmitButton && <LinearProgress />}
              {!!genericError && (
                <p
                  style={{
                    color: '#f44336',
                    textAlign: 'center',
                    fontSize: '0.75rem',
                    fontFamily: 'comfortaa',
                    padding: 0,
                    margin: 0,
                  }}
                >
                  {genericError}
                </p>
              )}
            </div>
          )}
        </Grid>

        {[LOOKUP_METHOD.CIN, LOOKUP_METHOD.VATNO].includes(lookupMethod) && supportedLookupMethods.includes(LOOKUP_METHOD.SEARCH) && (
          <Grid item>
            <Typography
              color="textPrimary"
              align="center"
              variant="subtitle2"
              style={{ fontSize: '0.75rem', textDecoration: 'underline', alignSelf: 'center', textAlign: 'center', cursor: 'pointer' }}
              onClick={() => switchLookupMethod(LOOKUP_METHOD.SEARCH)}
            >
              {settings.companyChapter.cinEdit.switchToCompanySearch}
            </Typography>
          </Grid>
        )}

        {lookupMethod === LOOKUP_METHOD.SEARCH && supportedLookupMethods.includes(LOOKUP_METHOD.CIN) && (
          <Grid item>
            <Typography
              color="textPrimary"
              align="center"
              variant="subtitle2"
              style={{ fontSize: '0.75rem', textDecoration: 'underline', alignSelf: 'center', textAlign: 'center', cursor: 'pointer' }}
              onClick={() => switchLookupMethod(LOOKUP_METHOD.CIN)}
            >
              {settings.companyChapter.cinEdit.switchToCompanyLookup}
            </Typography>
          </Grid>
        )}

        {lookupMethod === LOOKUP_METHOD.SEARCH && supportedLookupMethods.includes(LOOKUP_METHOD.VATNO) && (
          <Grid item>
            <Typography
              color="textPrimary"
              align="center"
              variant="subtitle2"
              style={{ fontSize: '0.75rem', textDecoration: 'underline', alignSelf: 'center', textAlign: 'center', cursor: 'pointer' }}
              onClick={() => switchLookupMethod(LOOKUP_METHOD.VATNO)}
            >
              {settings.companyChapter.cinEdit.switchToVatLookup}
            </Typography>
          </Grid>
        )}

        {lookupMethod !== LOOKUP_METHOD.MANUAL && (failureCount >= 2) && !signup.data.session?.merchantconfig?.signup_disallow_manual_after_failures && (
          <Grid item>
            <Typography
              color="textPrimary"
              align="center"
              variant="subtitle2"
              style={{ fontSize: '0.75rem', textDecoration: 'underline', alignSelf: 'center', textAlign: 'center', cursor: 'pointer' }}
              onClick={() => switchLookupMethod(LOOKUP_METHOD.MANUAL)}
            >
              {settings.companyChapter.cinEdit.enterCompanyManually}
            </Typography>
          </Grid>
        )}

        {lookupMethod === LOOKUP_METHOD.MANUAL && (supportedLookupMethods.length > 1) && (
          <Grid item>
            <Typography
              color="textPrimary"
              align="center"
              variant="subtitle2"
              style={{ fontSize: '0.75rem', textDecoration: 'underline', alignSelf: 'center', textAlign: 'center', cursor: 'pointer' }}
              onClick={() => switchLookupMethod(previousLookupMethod)}
            >
              {previousLookupMethod === LOOKUP_METHOD.CIN && settings.companyChapter.cinEdit.switchToCompanyLookup}
              {previousLookupMethod === LOOKUP_METHOD.SEARCH && settings.companyChapter.cinEdit.switchToCompanySearch}
              {previousLookupMethod === LOOKUP_METHOD.VATNO && settings.companyChapter.cinEdit.switchToVatLookup }
            </Typography>
          </Grid>
        )}

        {!lookupForm.noSubmitButton &&
          <Grid item>
            <Grid container direction="column" alignItems="center">
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  data-testid="CinCollectionButton"
                  type="submit"
                  onClick={handleFormSubmit}
                  disabled={fetching || hasFormError || !!genericError}
                  disableElevation
                >
                  {settings.companyChapter.cinEdit.buttons.continue}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        }
      </Grid>

      {signup.data.companyLookup.cin && shouldShowTerms && !signup.data.session?.merchantconfig?.disableCreditText && (
        <Box padding="20px 32px 0px 32px">
          <Typography color="textPrimary" align="center" variant="subtitle2" style={{ fontSize: '0.7rem' }}>
            {settings.companyChapter.creditInfoBlock.creditLookUpInfo}{' '}
            <Typography color="textPrimary" align="center" variant="subtitle2">
              <a
                style={{ fontSize: '0.7rem', cursor: 'pointer', textDecoration: 'underline' }}
                onClick={() => navigate(AppRoutes.CHAPTER_COMPANY_CREDIT_TERMS)}
              >
                {!signup.data.session?.merchantconfig?.disableCreditPop && (
                  <>{settings.companyChapter.creditInfoBlock.readMore}</>
                )}
              </a>
            </Typography>
          </Typography>
        </Box>
      )}
    </form>
  )
}

export default CompanyLookupChapter
