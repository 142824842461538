import React, { useState,useEffect } from 'react'
import { Button, Grid, TextField, Typography } from '@material-ui/core'
import { CompanyAddress } from '../application/types'
import AddressDropDown from './CompanyChapter/AddressDropDown'
import { useSignup } from '../signupContext'
import { settings } from '../resources'
import useEditChapterStyles from '../styles/useEditChapterStyles'
import AddIcon from '@material-ui/icons/Add';
import fetchr from '../application/fetchr'
interface Props {
  title: string
  description: string
  selectedAddress: CompanyAddress | undefined
  allowManual : boolean | undefined
  onChange: (address: CompanyAddress | undefined) => void
}

const titleCase = (text: string) => text[0].toUpperCase() + text.slice(1).toLowerCase()

const illegalCharacters = new RegExp('^[^\\[\\]<>{}\\\\()`;]*$')
const hasIllegalCharacters = (str: string) => !illegalCharacters.test(str)

const ExtraAddress = ( { title, description, selectedAddress, onChange ,allowManual }: Props) => {
  const signup = useSignup()
  const classes = useEditChapterStyles()
  const [pickingAddress, setPickingAddress] = useState(false)
  const [addressesToUse, setAddressesToUse] = useState<CompanyAddress[]>(
    JSON.parse(JSON.stringify(signup.data.company?.address))
  )
  const [address, setAddress] = useState('')
  const [city, setCity] = useState('')
  const [zip, setZip] = useState('')
  const [addressValidationError, setAddressValidationError] = useState('')
  const [cityValidationError, setCityValidationError] = useState('')
  const [zipValidationError, setZipValidationError] = useState('')

  useEffect(() => {
    if (allowManual && !addressesToUse?.find((e: { _id: string }) => e._id === 'own')) {
      setAddressesToUse((adr) => [...adr, { _id: 'own', streetaddress: '', zip: '', city: '', hq: false }])
    }
  }, [addressesToUse, allowManual])

  const setManualAddres = () => {
    if (
      !changeAddress(address) ||
      !changeCity(city) ||
      !changeZip(zip)
    ) {
      return
    }
    const inputAddress: Partial<CompanyAddress> = {
      streetaddress: address,
      city,
      zip,
      manualInput : manualAddress
    }
    fetchr(`${signup.data.url}/api/signup/address`, signup.data.token)
      .post(inputAddress as unknown as Record<string, string | boolean>)
      .then(response => response.json())
      .then(response => {
        onChange(response as CompanyAddress)
        setManualSelect(false)
        setPickingAddress(false)
      })
      .catch(() => {
        //
      })
  }

  const changeAddress = (address: string) => {
    if (address.length >= 1 && address.length < 100 && !hasIllegalCharacters(address)) {
      setAddressValidationError('')
      return true
    } else {
      setAddressValidationError(settings.commonErrors.addressError)
      return false
    }
  }
  const changeCity = (city: string) => {
    if (city.length >= 1 && city.length < 100 && !hasIllegalCharacters(city)) {
      setCityValidationError('')
      return true
    } else {
      setCityValidationError(settings.commonErrors.cityError)
      return false
    }
  }

  const changeZip = (zip: string) => {
    if (zip.length >= 3 && zip.length < 30 && !hasIllegalCharacters(zip)) {
      setZipValidationError('')
      return true
    } else {
      setZipValidationError(settings.commonErrors.zipError)
      return false
    }
  }
 // SHould this allow for manual inpout?
 // maybe an input from before
  const getEditButtonLabel = () => {
    if (pickingAddress) {
      return settings.finalizeSignUp.removeExtraAddress
    }
    return settings.userChapter.userChapterOverView.edit
  }

  const onAddressChange = (address?: CompanyAddress | null) => {
    if(address?._id==="own"){
      setManualSelect(true)
      return
    }

    if (address) {
      setManualSelect(false)
      onChange(address)
      setPickingAddress(false)
    }
  }
  const [manualAddress, setManualSelect] = useState(false)
  const onCancelPickingPressed: React.MouseEventHandler<HTMLDivElement> | undefined = (e) => {
    e.stopPropagation()
    onChange(undefined)
    setPickingAddress(false)
  }

  const onEditPressed = () => {
    setPickingAddress(true)
    if (selectedAddress) {
      onChange(undefined)
    }
  }

  const renderContent = () => {
    if (selectedAddress) {
      return (
        <Grid item style={{ width: '100%' }}>
          <Typography color="textPrimary" variant="body2" align="left">
            {selectedAddress.streetaddress}, {selectedAddress.zip}, {selectedAddress.city}
            </Typography>
        </Grid>
      )
    }

    if (!selectedAddress && !pickingAddress) {
      return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <AddIcon/>
          <Typography color="textPrimary" variant="body2" align="left" style={{ alignSelf: 'center', paddingTop: 2, paddingLeft: 2 }}>
            {description}
          </Typography>
        </div>
      )
    }

    if (pickingAddress) {
      return (
        <Grid item style={{ width: '100%' }}> 
        {!manualAddress && <Grid item style={{ width: '100%' }}>
          <AddressDropDown slim openByDefault addresses={addressesToUse} selectedAddress={selectedAddress} onChange={onAddressChange} />
          <Grid item> {allowManual && <Typography color="textPrimary" variant="body2" align={'right'} className={classes.changeButton}>
            <p className={classes.changeButton} onClick={(e) => {
              e.preventDefault()
              onChange(undefined)
              setManualSelect(!manualAddress)
            }}>{!manualAddress ? settings.companyChapter.manualAddress : settings.companyChapter.selectFromAddressList}</p>
          </Typography>
          }</Grid>
          </Grid>}
          {manualAddress && 
          
          <>
          <Grid item>
            <TextField
              data-testid="CompanyAddress"
              autoComplete="address"
              name="address"
              onChange={(e) => {
                setAddress(e.target.value)
                setAddressValidationError('')
              }}
              onBlur={(e) => changeAddress(e.target.value)}
              error={!!addressValidationError}
              helperText={addressValidationError}

              label={settings.commonTerms.address}
              fullWidth={true}
              value={address}
            />
          </Grid>
          <Grid item>
            <TextField
              data-testid="CompanyAddressCity"
              autoComplete="city"
              name="city"
              onChange={(e) => {
                setCity(e.target.value)
                setCityValidationError('')
              }}
              onBlur={(e) => changeCity(e.target.value)}
              error={!!cityValidationError}
              helperText={cityValidationError}
 
              label={settings.commonTerms.city}
              fullWidth={true}
              value={city}
            />
          </Grid>
          <Grid item>
            <TextField
              data-testid="CompanyZip"
              autoComplete="zip"
              name="zip"
              onChange={(e) => {
                setZip(e.target.value)
                setZipValidationError('')
              }}
              onBlur={(e) => changeZip(e.target.value)}
              error={!!zipValidationError}
              helperText={zipValidationError}
              label={settings.commonTerms.postalCode}
              fullWidth={true}
              value={zip}
            />
          </Grid>
          <Grid item style={{padding:"10px"}}>
          <a className={classes.editButtonSmall}  onClick={() => {setManualSelect(false)}}>{settings.companyChapter.selectFromAddressList}</a>
          <Button  variant="contained"
          color="secondary"  onClick={setManualAddres}>{settings.companyChapter.saveManualAddress }</Button></Grid>
        </>}
        </Grid>
      )
    }
  }

  return (
    <Grid container>
    <Grid item xs={12}>
      <Grid container justify="center">
        <Grid item className={classes.changeButton} xs={12} onClick={onEditPressed}>
          <Grid container direction="row">
            <Grid item xs={8}>
              <Typography variant="h5" component="h2" className={classes.smallTitle}>
                {titleCase(title)}
              </Typography>
            </Grid>
            {pickingAddress && (
              <Grid item xs={4} className={classes.editButtonSmall} onClick={onCancelPickingPressed}>
                <Typography color="textPrimary" variant="body2" align="right">
                  {settings.finalizeSignUp.removeExtraAddress}
                </Typography>
              </Grid>
            )}
            {selectedAddress && (
              <Grid item xs={4} className={classes.editButtonSmall}>
                <Typography color="textPrimary" variant="body2" align="right">
                  {getEditButtonLabel()}
                </Typography>
              </Grid>
            )}
          </Grid>
          <Grid container className={pickingAddress ? undefined : classes.roundedBorder} data-testid="UserOverviewPreviewContainer">
            {renderContent()}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
  )
}

export default ExtraAddress
