import { makeStyles } from "@material-ui/core"

const useEditChapterStyles = makeStyles((theme: any) => ({
  roundedBorder: {
    border: '1px solid #0000006b',
    borderRadius: theme.spacing(2.25),
    padding: '10px 20px',
  },
  changeButton: {
    cursor: 'pointer'
  },
  titleText: {
    fontWeight: theme.typography.fontWeightBold,
    paddingBottom: theme.spacing(0.5),
  },
  cinText: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  bigTitle: {
    textAlign: 'left',
    marginLeft: theme.spacing(1.0),
    marginBottom: theme.spacing(2.5),
  },
  smallTitle: {
    textAlign: 'left',
    fontSize: theme.typography.body1.fontSize,
    marginBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(2.25),
  },
  editButton: {
    paddingLeft: theme.spacing(2.25),
    paddingRight: theme.spacing(2.25),
    paddingBottom: theme.spacing(0.5),
    paddingTop: theme.spacing(1.0)
  },
  editButtonSmall: {
    paddingRight: theme.spacing(2.25),
    paddingTop: theme.spacing(0.25)
  },
  unsetWhiteSpace: {
    whiteSpace: 'unset',
    '& .MuiSelect-selectMenu': {
      whiteSpace: 'unset',
    }
  }
}))

export default useEditChapterStyles
