/* eslint-disable no-undefined */
import React, { FC } from 'react'
import isMobile from 'ismobilejs'
import { BankIdHintCode, BankIdPendingHintCode, BankIdFailedHintCode, BankIdOtherEnum } from '../../../bankid/types'

export const getByHintCode = (
  hintCode: BankIdHintCode | null | undefined | string = BankIdPendingHintCode.NO_CLIENT,
  translations: Record<string, any>,
  showingQr: boolean,
  attemptedToOpenSameDevice: boolean
): string | null => {
  const mobile = isMobile(navigator.userAgent).phone

  switch (hintCode) {
    case BankIdFailedHintCode.CANCELLED:
      return translations.rfa.rfa3

    case BankIdOtherEnum.ALREADY_IN_PROGRESS:
      return translations.rfa.rfa4

    case BankIdOtherEnum.REQUEST_TIMEOUT:
    case BankIdOtherEnum.MAINTENANCE:
    case BankIdOtherEnum.INTERNAL_ERROR:
      return translations.rfa.rfa5

    case BankIdFailedHintCode.USER_CANCEL:
      return translations.rfa.rfa6

    case BankIdFailedHintCode.BRIQ_INCORRECTSIGNATORY:
      return translations.incorrectSignee

    case BankIdFailedHintCode.EXPIRED_TRANSACTION:
      return translations.rfa.rfa8

    case BankIdPendingHintCode.USER_SIGN:
      return translations.rfa.rfa9

    case BankIdFailedHintCode.CERTIFICATE_ERR:
      return translations.rfa.rfa16

    case BankIdFailedHintCode.START_FAILED:
      if (mobile) {
        if (attemptedToOpenSameDevice) {
          return translations.rfa.rfa17a
        } else {
          return translations.rfa.rfa6
        }
      } else {
        // return translations.rfa.rfa17b
        return translations.rfa.rfa6 // Replaced super long error message with a shorter one
      }

    case BankIdPendingHintCode.OUTSTANDING_TRANSACTION:
    case BankIdPendingHintCode.NO_CLIENT:
    case undefined:
    case null:
      if (attemptedToOpenSameDevice) {
        return translations.rfa.rfa13
      } else if (showingQr) {
        return translations.clickOrScanToBeginVerification
      } else {
        return translations.clickToBeginVerification
      }

    default:
      return translations.rfa.rfa22
  }
}

const RfaMessage: FC<{
  providerData: any | undefined
  translations: Record<string, any>
  showingQr: boolean
  attemptedToOpenSameDevice: boolean
}> = ({ providerData, translations, showingQr, attemptedToOpenSameDevice }) => {
  const rfa = getByHintCode(
    providerData?.hintCode,
    translations,
    showingQr,
    attemptedToOpenSameDevice
  )

  if (!rfa) {
    return null
  }

  return (
    <div data-testid="BankIdRfa" style={{ padding: 16, paddingTop: 12, paddingBottom: 4 }}>
      {rfa}
    </div>
  )
}

export default RfaMessage
