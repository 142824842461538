import React, { FC } from 'react'
import { Button, CircularProgress, makeStyles } from '@material-ui/core'

interface Props {
  data: { value: string; label: string }[]
  selectedValue?: string | undefined
  defaultValue?: string | undefined
  disabled?: boolean
  loading?: boolean
  smallText?: boolean
  maxColumns?: number
  onChange: (value: string) => void
}

const useStyles = makeStyles((theme) => ({
  spinnWrapper: {
    position: "absolute",
    marginTop: theme.spacing(1)
  },
}))

const EnumRadio: FC<Props> = ({ data, selectedValue, defaultValue, disabled, loading, maxColumns = 2, onChange }) => {
  const classes = useStyles()

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        gap: 6,
        flexDirection: (data.length as number) > maxColumns ? 'column' : 'row',
        justifyContent: 'center'
      }}
    >
      {data.map(({ value, label }) => {
        const selected = value === selectedValue || (!selectedValue && value === defaultValue)

        return (
          <div key={`enumRadio-${value}`}>
            <Button
              variant={selected ? 'contained' : 'outlined'}
              color="secondary"
              onClick={() => !selected && !disabled && onChange(value)}
              style={{ minWidth: 140, flexGrow: 1, opacity: loading ? 0.7 : 1.0 }}
              disableElevation
              size="small"
            >
              {label.toUpperCase()}
              {loading && !selected && (
                <div className={classes.spinnWrapper}>
                  <CircularProgress size="15px" />
                </div>
              )}
            </Button>
          </div>
        )
      })}
    </div>
  )
}

export default EnumRadio
