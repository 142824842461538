import { createMuiTheme } from '@material-ui/core/styles'
import createPalette from '@material-ui/core/styles/createPalette'

const theme = createMuiTheme({
  palette: createPalette({
    primary: {
      main: '#000',
    },
    secondary: {
      main: '#000',
    },
  }),
  shape: {
    borderRadius: 11,
  },
  typography: {
    fontFamily: 'Comfortaa',
    button: {
      fontSize: '1em',
      textTransform: 'none',
    },
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        "& $notchedOutline": {
          borderColor: "#0000006b",
          borderRadius: 18
        },
      }
    },
    MuiButton: {
      contained: {
        backgroundColor: '#000',
        border: 0,
        color: '#fff',
        '&:disabled': {
          backgroundColor: '##000000a3',
          color: '#fff',
        },
        '&:hover': {
          backgroundColor: '#000000c7',
        },
      },
      root: {
        padding: '8px 0',
        minWidth: '250px',
      },
    },
    MuiInputLabel: {
      root: {
        textAlign: 'center',
        width: '100%',
      },
      shrink: {
        textAlign: 'left',
      },
    },
  },
})

export default theme
