import React, { useEffect, useRef, useState } from 'react'
import { Button, Grid, TextField } from '@material-ui/core'
import CompanyOverview from '../CompanyChapter/CompanyOverview'
import { AppRoutes, CUSTOMER_TYPE, UserBlock, VALIDATION_ERROR_CODE } from '../../application/types'
import { useSignup } from '../../signupContext'
import { settings } from '../../resources'
import { PhoneNumberUtil } from 'google-libphonenumber'
import * as EmailValidator from 'email-validator'
import useNavigateReplace from '../../hooks/useNavigateReplace'
import ConsumerOverview from '../CompanyChapter/ConsumerOverview'
import { getCustomFieldsConfig } from '../../signupHelper'

const phoneUtil = PhoneNumberUtil.getInstance()

const illegalCharacters = new RegExp('^[^\\[\\]<>{}\\\\()`;]*$')
const hasIllegalCharacters = (str: string) => !illegalCharacters.test(str)

const UserEdit = () => {
  const signup = useSignup()
  const navigate = useNavigateReplace()

  const [firstNameValidationError, setFirstNameValidationError] = useState('')
  const [lastNameValidationError, setLastNameValidationError] = useState('')
  const [emailValidationError, setEmailValidationError] = useState('')
  const [phoneValidationError, setPhoneValidationError] = useState('')
  const firstNameRef = useRef<HTMLInputElement>()
  const lastNameRef = useRef<HTMLInputElement>()
  const emailRef = useRef<HTMLInputElement>()
  const phoneRef = useRef<HTMLInputElement>()

  useEffect(() => {
    const errorDescriptions: Record<VALIDATION_ERROR_CODE, string> = {
      [VALIDATION_ERROR_CODE.ALREADY_EXISTS]: settings?.userChapter.userEdit.errors.alreadyExists,
      [VALIDATION_ERROR_CODE.INVALID]: settings?.userChapter.userEdit.errors.invalid,
    }

    signup.data.merchantUserErrors.map((merchantError) => {
      const errorM = errorDescriptions[merchantError.errorCode]
      switch (merchantError.errorField) {
        case 'firstName':
          setFirstNameValidationError(errorM)
          break

        case 'lastName':
          setLastNameValidationError(errorM)
          break

        case 'email':
          setEmailValidationError(errorM)
          break

        case 'phone':
          setPhoneValidationError(errorM)
          break
      }
    })
  }, [signup.data.merchantUserErrors])

  const handleOrgSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const inputData = {
      firstName: firstNameRef.current?.value,
      lastName: lastNameRef.current?.value,
      email: emailRef.current?.value,
      phone: phoneRef.current?.value,
    } as UserBlock

    if (
      !changeFirstName(inputData.firstName) ||
      !changeLastName(inputData.lastName) ||
      !changeEmail(inputData.email) ||
      !changePhone(inputData.phone)
    ) {
      return
    }

    signup.actions.setUser(inputData)

    if (
      getCustomFieldsConfig(signup.data.session)?.groups?.length &&
      !signup.data.additionalInformation /*  &&
      signup.data.session?.customerType !==  CUSTOMER_TYPE.CONSUMER */
    ) {
      navigate(AppRoutes.CHAPTER_ADDITIONAL_INFORMATION_EDIT)
    } else {
      navigate(AppRoutes.HOME)
    }
  }

  const isNameValid = (name: string) => {
    if (!signup.data.session?.merchantconfig?.signup_remove_first_and_lastname) {
      if (name.length < 1 || name.length > 30) {
        return false
      }

      if (hasIllegalCharacters(name)) {
        return false
      }
    }

    return true
  }

  const changeFirstName = (firstName: string) => {
    if (isNameValid(firstName)) {
      setFirstNameValidationError('')
      return true
    } else {
      setFirstNameValidationError(settings.userChapter.userEdit.errors.firstNameError)
      return false
    }
  }

  const changeLastName = (lastName: string) => {
    if (isNameValid(lastName)) {
      setLastNameValidationError('')
      return true
    } else {
      setLastNameValidationError(settings.userChapter.userEdit.errors.lastNameError)
      return false
    }
  }
  const verifyEmail = (email: string) => {
    if (emailRef.current?.value) {
      if (EmailValidator.validate(email) && EmailValidator.validate(emailRef.current.value.toString())) {
        if (email.toLowerCase() !== emailRef.current?.value.toLowerCase())
          setEmailValidationError(settings.userChapter.userEdit.errors.emailConfirmError)
        return false
      } else {
        setEmailValidationError(settings.userChapter.userEdit.errors.emailError)
        return false
      }
    }
    return true
  }
  const changeEmail = (email: string) => {
    if (EmailValidator.validate(email)) {
      setEmailValidationError('')
      return true
    } else {
      setEmailValidationError(settings.userChapter.userEdit.errors.emailError)
      return false
    }
  }

  const phoneRegex = new RegExp(/^(?=.*[0-9])[- +()0-9]+$/) // 0-9, -, +, (), whitespace
  const changePhone = (phone: string) => {
    try {
      let phonevalidation
      if (signup.data.session?.country.toUpperCase() === 'GLOBAL') {
        phonevalidation = phoneRegex.test(phone)
      } else {
        const number = phoneUtil.parseAndKeepRawInput(phone, signup.data.session?.country.toUpperCase() || 'SE')
        phonevalidation = phoneUtil.isValidNumber(number) && phoneRegex.test(phone)
      }

      if (phonevalidation) {
        setPhoneValidationError('')
        return true
      } else {
        setPhoneValidationError(settings.userChapter.userEdit.errors.phoneError)
        return false
      }
    } catch (e) {
      setPhoneValidationError(settings.userChapter.userEdit.errors.phoneError)
      return false
    }
  }

  return (
    <>
      <form onSubmit={handleOrgSubmit}>
        <Grid container direction="column" spacing={4} data-testid="UserEditContainer">
          {signup.data.session?.customerType !== CUSTOMER_TYPE.CONSUMER && (
            <Grid item>
              <CompanyOverview />
            </Grid>
          )}
          {signup.data.session?.customerType === CUSTOMER_TYPE.CONSUMER && (
            <Grid item>
              <ConsumerOverview />
            </Grid>
          )}
          {!signup.data.session?.merchantconfig?.signup_remove_first_and_lastname && (
            <>
              <Grid item>
                <TextField
                  data-testid="UserEditFieldFirstName"
                  autoComplete="given-name"
                  name="firstname"
                  onChange={() => setFirstNameValidationError('')}
                  onBlur={(e) => changeFirstName(e.target.value)}
                  error={!!firstNameValidationError}
                  helperText={firstNameValidationError}
                  inputRef={firstNameRef}
                  defaultValue={signup.data.user?.firstName}
                  label={settings.userChapter.userEdit.firstNameLabel}
                  fullWidth={true}
                  disabled={signup.data.session?.customerType === CUSTOMER_TYPE.CONSUMER}
                />
              </Grid>
              <Grid item>
                <TextField
                  data-testid="UserEditFieldFamilyName"
                  autoComplete="family-name"
                  name="lastname"
                  onChange={() => setLastNameValidationError('')}
                  onBlur={(e) => changeLastName(e.target.value)}
                  error={!!lastNameValidationError}
                  helperText={lastNameValidationError}
                  inputRef={lastNameRef}
                  defaultValue={signup.data.user?.lastName}
                  label={settings.userChapter.userEdit.lastNameLabel}
                  fullWidth={true}
                  disabled={signup.data.session?.customerType === CUSTOMER_TYPE.CONSUMER}
                />
              </Grid>
            </>
          )}
          <Grid item>
            <TextField
              data-testid="UserEditFieldEmail"
              autoComplete="email"
              name="email"
              onChange={() => setEmailValidationError('')}
              onBlur={(e) => changeEmail(e.target.value)}
              error={!!emailValidationError}
              helperText={emailValidationError}
              inputRef={emailRef}
              defaultValue={signup.data.user?.email}
              type="email"
              label={settings.userChapter.userEdit.emailLabel}
              fullWidth={true}
            />
          </Grid>
          {signup.data.session?.merchantconfig?.signup_email_confirm_field && (
            <Grid item>
              <TextField
                data-testid="UserEditFieldConfirmEmail"
                autoComplete="off"
                name="email-confirm"
                onChange={() => setEmailValidationError('')}
                onBlur={(e) => verifyEmail(e.target.value)}
                error={!!emailValidationError}
                helperText={emailValidationError}
                defaultValue={signup.data.user?.email}
                type="email"
                label={settings.userChapter.userEdit.emailConfirmLabel}
                fullWidth={true}
              />
            </Grid>
          )}
          <Grid item>
            <TextField
              autoComplete="tel"
              data-testid="UserEditFieldPhone"
              name="phone"
              onChange={() => setPhoneValidationError('')}
              onBlur={(e) => changePhone(e.target.value)}
              error={!!phoneValidationError}
              helperText={phoneValidationError}
              inputRef={phoneRef}
              defaultValue={signup.data.user?.phone}
              label={settings.userChapter.userEdit.phoneLabel}
              type="phone"
              fullWidth={true}
            />
          </Grid>
          <Grid item>
            <Grid container direction="column" alignItems="center">
              <Grid item>
                <Button
                  variant="contained"
                  data-testid="UserEditButton"
                  color="secondary"
                  type="submit"
                  disabled={
                    !!firstNameValidationError ||
                    !!lastNameValidationError ||
                    !!emailValidationError ||
                    !!phoneValidationError
                  }
                  fullWidth={true}
                >
                  {settings.userChapter.userEdit.buttons.continue}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  )
}

export default UserEdit
