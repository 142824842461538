import { useRef, useEffect, DependencyList } from 'react'

const asyncIntervals: boolean[] = []

const runAsyncInterval = async (
  callback: (stopInterval: () => void) => void,
  interval: number,
  intervalIndex: number,
  stopInterval: () => void
) => {
  if (asyncIntervals[intervalIndex]) {
    setTimeout(async () => {
      if (asyncIntervals[intervalIndex]) {
        await callback(stopInterval)
        runAsyncInterval(callback, interval, intervalIndex, stopInterval)
      }
    }, interval)
  }
}

const setAsyncInterval = (callback: (stopInterval: () => void) => void, interval: number, stopInterval: () => void) => {
  if (callback && typeof callback === 'function') {
    const intervalIndex = asyncIntervals.length
    asyncIntervals.push(true)
    runAsyncInterval(callback, interval, intervalIndex, stopInterval)
    return intervalIndex
  } else {
    throw new Error('Callback must be a function')
  }
}

const clearAsyncInterval = (intervalIndex: number) => {
  if (asyncIntervals[intervalIndex]) {
    asyncIntervals[intervalIndex] = false
  }
}

function useAsyncInterval2(
  callback: (stopInterval: () => void) => void,
  deps: DependencyList,
  delay: number | null
): void {
  const savedCallback = useRef<(stopInterval: () => void) => void | null>()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  })

  // Set up the interval.
  useEffect(() => {
    async function tick(stopInterval: () => void) {
      if (typeof savedCallback?.current !== 'undefined') {
        await savedCallback?.current(stopInterval)
      }
    }

    if (delay !== null) {
      const id = setAsyncInterval(tick, delay, () => clearAsyncInterval(id))
      return () => clearAsyncInterval(id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delay, ...deps])
}

export default useAsyncInterval2
