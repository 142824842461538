import React, { useState } from 'react'
import { CompanyAddress } from '../../application/types'
import { Autocomplete } from '@material-ui/lab'
import { Grid, TextField, Typography } from '@material-ui/core'
import { settings } from '../../resources'
import { useSignup } from '../../signupContext'
import LocationOnIcon from '@material-ui/icons/LocationOn'

interface Props {
  addresses: CompanyAddress[] | undefined
  selectedAddress: CompanyAddress | undefined
  slim?: boolean
  openByDefault?: boolean
  allowManual?: boolean
  onChange: (value?: CompanyAddress | null) => void
  allowClear?: boolean
}

const AddressDropDown = ({ addresses, selectedAddress, slim, openByDefault, onChange, allowClear }: Props) => {
  const signup = useSignup()
  const [open, setOpen] = useState(!!openByDefault)

  return (
    <Autocomplete
      blurOnSelect
      autoHighlight
      open={open}
      onFocus={() => setOpen(true)}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      options={addresses || []}
      onChange={(event, value) => {
        event.stopPropagation()
        if (value || allowClear) {
          onChange(value)
        }
      }}
      value={selectedAddress || null}
      fullWidth
      getOptionLabel={(option: CompanyAddress) => option.streetaddress}
      renderOption={(option: CompanyAddress) => {
        if (option._id === 'own') {
          return (
            <Grid container alignItems="center">
              <Grid item>
                <Grid item xs>
                  <Typography variant="body2" color="textSecondary">
                    {settings.companyChapter.manualAddress}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )
        } else {
          return (
            <Grid container alignItems="center">
              <Grid item>
                <LocationOnIcon />
              </Grid>
              <Grid item xs>
                <Typography variant="body2" color="textSecondary">
                  {signup.data.company?.companyName}
                </Typography>
                {option.streetaddress}
                <Typography variant="body2" color="textSecondary">
                  {option.zip} {option.city}
                </Typography>
              </Grid>
            </Grid>
          )
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={slim && !open ? undefined : settings.companyChapter.companyDropDown.selectorLabel}
          variant="outlined"
          size={slim ? 'small' : 'medium'}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
    />
  )
}

export default AddressDropDown
