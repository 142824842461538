// eslint-disable-next-line @typescript-eslint/no-explicit-any
let settings: Record<string, any>

const load = async (locale: string) => {
    const lang = await import(`./locale/${locale}.json`)
    settings = {
        ...lang.default
    }
}


export { settings as default, load }
