import React, { FC, useEffect, useState } from 'react'
import { settings } from '../../resources'
import { CompanyLookup, VALIDATION_ERROR_CODE } from '../../application/types'
import IconTextInput from './IconTextInput'
import BusinessIcon from '@material-ui/icons/Business'
import { useSignup } from '../../signupContext'

const errorDescriptions: Record<VALIDATION_ERROR_CODE, string> = {
  [VALIDATION_ERROR_CODE.ALREADY_EXISTS]: settings?.companyChapter.cinEdit.errors.triggeredErrorDuplicateCompany,
  [VALIDATION_ERROR_CODE.INVALID]: settings?.companyChapter.cinEdit.errors.somethingWentWrong,
}

const illegalCharacters = new RegExp('^[^\\[\\]<>{}\\\\()`;]*$')
const hasIllegalCharacters = (str: string) => !illegalCharacters.test(str)

interface Props {
  fetching: boolean
  setError: (hasError: boolean) => void
  setCompanyLookup: (companyLookup: CompanyLookup) => void
}

const CinEdit: FC<Props> = ({ fetching, setError, setCompanyLookup }) => {
  const signup = useSignup()

  const [cinValidationError, setCinValidationError] = useState('')

  useEffect(() => {
    validateCin(signup.data.companyLookup.cin ?? ('' as string))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (Array.isArray(signup.data?.merchantUserErrors) && signup.data.merchantUserErrors.length) {
      signup.data.merchantUserErrors.map((merchantError) => {
        const errorM = errorDescriptions[merchantError.errorCode]
        switch (merchantError.errorField) {
          case 'cin':
            setCinValidationError(errorM)
            break
          default:
            break
        }
      })
    }
  }, [signup.data?.merchantUserErrors])

  const formatCin = (cin: string) => {
    const value = cin.trim()
    .replaceAll('–', '-')
    .replaceAll('—', '-')
    .replaceAll('−', '-')
    // Replace No-Break Space with normal space
    .replaceAll(' ', ' ')

    switch (signup.data.session?.country.toLowerCase()) {
      case 'se':
      case 'fi':
      case 'no':
      case 'dk':
      case 'nl':
        return value.replace(/-/g, '').replace(/ /g, '')

      case 'ch':
        return value.replace(/-/g, '').replace(/\./g, '')

      case 'gb':
        if (!isNaN(value as unknown as number)) {
          return value.padStart(8, '0')
        }
        return value

      default:
        return value
    }
  }

  const validateCin = (inputCin: string) => {
    const formattedCin = formatCin(inputCin)

    let cinIsValid = true
    switch (signup.data.session?.country.toLowerCase()) {
      case 'se': // 2021003062 or 202100-3062
        cinIsValid = formattedCin.length === 10
        break

      case 'no': // 980213250
        cinIsValid = formattedCin.length === 9
        break

      case 'dk':
        cinIsValid = formattedCin.length === 8
        break

      case 'fi':
        cinIsValid = formattedCin.length >= 8 && formattedCin.length <= 10
        break

      case 'nl': // 17066875 or 170668750008, KVK or vestigingsnummer
        cinIsValid = formattedCin.length >= 8 && formattedCin.length <= 12
        break

      case 'ch': // CHE-475.580.090 or CHE475580090
        cinIsValid = formattedCin.length >= 12 && formattedCin.length <= 15
        break

      default:
        cinIsValid = formattedCin.length >= 4 && formattedCin.length <= 14
        break
    }

    cinIsValid &&= !hasIllegalCharacters(formattedCin)

    if (cinIsValid) {
      setCompanyLookup({
        cin: formattedCin,
      })
      setCinValidationError('')
      setError(false)
    } else {
      setError(true)

      if (formattedCin?.length) {
        setCinValidationError(settings.commonErrors.cinError)
      }
    }
  }

  const country = signup.data.session?.country.toLowerCase()

  return (
    <div style={{ marginTop: 20, marginBottom: 20 }}>
      <IconTextInput
        validationError={cinValidationError}
        onChange={(e) => validateCin(e.target.value)}
        disabled={fetching}
        defaultValue={signup.data.companyLookup.cin}
        name={'company_identification_number' + (country === 'se' ? '' : `_${country}`)}
        label={settings.companyChapter.cinEdit.cinLabel}
        testId="CinCollectionInput"
        Icon={<BusinessIcon />}
        autoFocus={!signup.data.companyLookup?.cin}
      />
    </div>
  )
}

export default CinEdit
