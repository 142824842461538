import React, { FC, useEffect, useState } from 'react'
import { useBankId } from './Context'
import Qr from './Qr'
import { Button, Typography } from '@material-ui/core'
import Logo from './Logo'
import { getAutoStartUrl } from './getStartUrl'
import { BankIdStatus } from './types'
import { settings } from '../../resources'
import johnny from '../../johnny'
import fetchr from '../../application/fetchr'
import { useSignup } from '../../signupContext'

interface Props {
  loading: boolean
}

const BankIDMenu: FC<Props> = ({ loading }) => {
  const {  startBankID, startState, collectState, setCollectState } = useBankId()
  const signup = useSignup()

  const [sameDeviceLoading, setSameDeviceLoading] = useState(false)

  useEffect(() => {
    startBankID()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSameDevice = async () => {
    if (startState?.autoStartToken && (!collectState || collectState?.status === BankIdStatus.PENDING)) {
      window.open(getAutoStartUrl(startState.autoStartToken))
    } else {
      try {
        setSameDeviceLoading(true)
        const newStartState = await startBankID()
        window.open(getAutoStartUrl(newStartState.autoStartToken))
      } finally {
        setSameDeviceLoading(false)
      }
    }
  }

  const simulateBankID = async () => {
    setSameDeviceLoading(true)
    await fetchr(signup.data.url + '/api/bankid/signup/simulate', signup.data.token).post({})
      .then(res => res.json())
      .then(data => {
        setCollectState(data)
      })
      .finally(() => setSameDeviceLoading(false))
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: 8,
        width: '100%',
        marginTop: 0,
        marginBottom: 16,
      }}
    >
      <div style={{ marginTop: 16, marginBottom: 12, display: 'flex', flexDirection: 'column', gap: 20, justifyContent: 'center', alignItems: 'center', width: '100%' }}>
        <Logo />
        <Typography color="textPrimary" variant="body1" align="center">
          {settings.bankid.verifyWithBankIDToContinue}
        </Typography>
      </div>

      <Qr loading={loading} />

      <Typography color="textPrimary" variant="body2" align="center" style={{ paddingTop: 16 }}>
        {settings.bankid.or}
      </Typography>
      <div>
        <Button variant="contained" color="secondary" type="button" onClick={onSameDevice} disabled={collectState?.status === BankIdStatus.COMPLETE || sameDeviceLoading || loading}>
          {settings.bankid.openBankIDApp}
        </Button>
      </div>

      {!!johnny.getTestMode() && (
        <>
          <Typography color="textPrimary" variant="body2" align="center" style={{ paddingTop: 16 }}>
            {settings.bankid.or}
          </Typography>
          <div>
            <Button variant="contained" color="primary" type="button" onClick={simulateBankID} disabled={collectState?.status === BankIdStatus.COMPLETE || sameDeviceLoading || loading}>
              Simulate BankID
            </Button>
          </div>
        </>
      )}
    </div>
  )
}

export default BankIDMenu
