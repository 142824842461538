import { AdditionalInformationConfig, CUSTOMER_TYPE, SignupSession } from "./application/types"

const getCustomFieldsConfig = (session?: SignupSession): AdditionalInformationConfig | undefined => {
  if (CUSTOMER_TYPE.CONSUMER === session?.customerType) {
    return session?.merchantconfig?.signup_custom_fields_b2c
  } else {
    return session?.merchantconfig?.signup_custom_fields
  }
}

export {
  getCustomFieldsConfig
}