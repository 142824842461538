import { createMuiTheme, ThemeOptions, ThemeProvider } from "@material-ui/core/styles"
import createPalette from "@material-ui/core/styles/createPalette"
import React, { useEffect } from "react"
import { useSignup } from "../../signupContext"
import { briqpay, stick, unident, template } from "../../themes"
import WebFont from "webfontloader"

const themes = {
  briqpay,
  stick,
  unident,
  template,
}

type ThemeSwitcherProps = {
  children: React.ReactNode
}

function ThemeSwitcher({ children }: ThemeSwitcherProps) {
  const signup = useSignup()

  useEffect(() => {
      WebFont.load({
        custom: {
          families: [signup.data.session?.merchantconfig?.signup_font?.name || 'Comfortaa'],
          urls: [signup.data.session?.merchantconfig?.signup_font?.link || 'https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap'],
        },
      })
  }, [signup.data.session?.merchantconfig?.signup_font])

  let theme
  try {
    const themeOptions = template
      .replace('MAINBUTTONCOLOR', signup.data.session?.merchantconfig?.signup_colors?.button || '"')
      .replace('MAINTEXTCOLOR', signup.data.session?.merchantconfig?.signup_colors?.buttonText || '"')
      .replace('DISABLEDBUTTONCOLOR', signup.data.session?.merchantconfig?.signup_colors?.buttonDisabled || '"')
      .replace('DISABLEDTEXTCOLOR', signup.data.session?.merchantconfig?.signup_colors?.buttonTextDisabled || '"')
    const themeOptionsParsed = JSON.parse(themeOptions)

    const muiThemeSettings: ThemeOptions = {
      ...themeOptionsParsed.theme,
      palette: createPalette(themeOptionsParsed.palette),
      typography: {
        fontFamily: signup.data.session?.merchantconfig?.signup_font?.name || 'Comfortaa'
      }
    }
    theme = createMuiTheme(muiThemeSettings)

  } catch (e) {
    theme = themes.briqpay
  }

  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  )
}

export default ThemeSwitcher
