import { Grid, Switch, FormLabel } from '@material-ui/core'
import React from 'react'
import CompanyOverview from './CompanyChapter/CompanyOverview'
import FinalizeSignup from './FinalizeSignup/FinalizeSignup'
import UserOverview from './UserChapter/UserOverview'
import { makeStyles } from '@material-ui/core/styles'
import AdditionalInformationOverview from './AdditionalInformationChapter/AdditionalInformationOverview'
import { useSignup } from '../signupContext'
import ExtraAddress from './ExtraAddress'
import { settings } from '../resources'
import AddressOverview from './CompanyChapter/AddressOverview'
import { CUSTOMER_TYPE } from '../application/types'

const useStyles = makeStyles((theme) => ({
  purchaseButtonMargin: {
    marginTop: theme.spacing(5)
  }
}))

const ChapterOverview = () => {
  const signup = useSignup()
  const classes = useStyles()

  const signupTerms = signup.data.session?.merchantconfig?.signup_terms
  const signupNewsletter = signup.data.session?.merchantconfig?.signup_newsletter

  const shippingConfig = signup.data.session?.merchantconfig?.signup_shipping_address
  const postalConfig = signup.data.session?.merchantconfig?.signup_postal_address

  return (
    <>
      <Grid container direction="column" spacing={2}>
        {signup.data.session?.customerType !== CUSTOMER_TYPE.CONSUMER && (
          <Grid item>
            <CompanyOverview bigTitle={false} />
          </Grid>
        )}
        {shippingConfig?.enabled && (
          <Grid item>
            <ExtraAddress
              title={settings.finalizeSignUp.shippingAddress}
              description={settings.finalizeSignUp.clickToAddOtherShippingAddress}
              selectedAddress={signup.data.selectedShippingAddress}
              allowManual={signup.data.session?.merchantconfig?.signup_allowed_manual_addresses?.includes('shipping')}
              onChange={signup.actions.setSelectedShippingAddress}
            />
          </Grid>
        )}
        {postalConfig?.enabled && (
          <Grid item>
            <ExtraAddress
              title={settings.finalizeSignUp.postalAddress}
              description={settings.finalizeSignUp.clickToAddOtherPostalAddress}
              selectedAddress={signup.data.selectedPostalAddress}
              allowManual={signup.data.session?.merchantconfig?.signup_allowed_manual_addresses?.includes('postal')}
              onChange={signup.actions.setSelectedPostalAddress}
            />
          </Grid>
        )}
        <Grid item>
          <UserOverview bigTitle={false} />
        </Grid>

        {signup.data.session?.customerType === CUSTOMER_TYPE.CONSUMER && (
          <Grid item>
            <AddressOverview bigTitle={false} />
          </Grid>
        )}

        {!!Object.keys(signup.data.additionalInformation ?? {}).length && (
          <Grid item>
            <AdditionalInformationOverview bigTitle={false} />
          </Grid>
        )}
        {signupTerms?.enabled && (
          <Grid item style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Switch
              defaultChecked={!!signup.data.termsAccepted}
              onChange={(e) => signup.actions.setTermsAccepted(e.target.checked)}
            />
            <FormLabel component="legend" style={{ fontSize: '0.8rem' }}>
              {signupTerms.text}{' '}
              <a href={signupTerms.url} rel="noreferrer" target="_blank">
                {signupTerms.linkText}
              </a>{' '}
            </FormLabel>
          </Grid>
        )}
        {signupNewsletter?.enabled && (
          <Grid item style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '-16px' }}>
            <Switch
              defaultChecked={!!signup.data.newsletter}
              onChange={(e) => signup.actions.setNewsletter(e.target.checked)}
            />
            {signupNewsletter.url ? (
              <FormLabel component="legend" style={{ fontSize: '0.8rem' }}>
                {signupNewsletter.text}{' '}
                <a href={signupNewsletter.url} rel="noreferrer" target="_blank">
                  {signupNewsletter.linkText}
                </a>{' '}
              </FormLabel>
            ) : (
              <FormLabel component="legend" style={{ fontSize: '0.8rem' }}>
                {signupNewsletter.text}
              </FormLabel>
            )}
          </Grid>
        )}
        <Grid item className={classes.purchaseButtonMargin}>
          <FinalizeSignup />
        </Grid>
      </Grid>
    </>
  )
}

export default ChapterOverview
