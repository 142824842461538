import React from 'react'
import ChapterOverview from '../components/ChapterOverview'

const Overview = () => {
  return (
    <div className="App">
      <ChapterOverview />
    </div>
  )
}

export default Overview
