import React, { useMemo, useState } from 'react'
import {
  Button,
  Grid,
  TextField,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  Switch,
} from '@material-ui/core'
import {
  AdditionalInformationConfig,
  AdditionalInformationCustomField,
  AdditionalInformationCustomFieldSelect,
  AdditionalInformationCustomFieldType,
  AppRoutes,
} from '../../application/types'
import { useSignup } from '../../signupContext'
import { settings } from '../../resources'
import useEditChapterStyles from '../../styles/useEditChapterStyles'
import { PhoneNumberUtil } from 'google-libphonenumber'
import * as EmailValidator from 'email-validator'
import useNavigateReplace from '../../hooks/useNavigateReplace'
import { getCustomFieldsConfig } from '../../signupHelper'

const phoneUtil = PhoneNumberUtil.getInstance()

const illegalCharacters = new RegExp('^[^\\[\\]<>{}\\\\`;]*$')
const hasIllegalCharacters = (str: string) => !illegalCharacters.test(str)

const getInitialFieldValue = (
  customField: AdditionalInformationCustomField,
  sessionAdditionalInformation: Record<string, unknown> | undefined
) => {
  const existingValue = sessionAdditionalInformation?.[customField.fieldName]
  if (existingValue !== undefined) {
    return existingValue
  }

  switch (customField.type) {
    case AdditionalInformationCustomFieldType.RADIO:
      return customField.values[0]

    case AdditionalInformationCustomFieldType.SELECT:
      return customField.emptyDropdownValue ? undefined : customField.values[0]

    case AdditionalInformationCustomFieldType.SWITCH:
      return false

    case AdditionalInformationCustomFieldType.NUMBER:
      return 0

    default:
      return ''
  }
}

const phoneRegex = new RegExp(/^(?=.*[0-9])[- +()0-9]+$/) // 0-9, -, +, (), whitespace

const AdditionalInformationEdit = () => {
  const signup = useSignup()
  const navigate = useNavigateReplace()
  const classes = useEditChapterStyles()
  const customFieldsConfig = getCustomFieldsConfig(signup.data.session)

  const flatFields = customFieldsConfig?.groups?.reduce((acc, groupCur) => {
    groupCur.rows.map((row) =>
      row.map((cf) => {
        acc.push(cf)
      })
    )
    return acc
  }, [] as AdditionalInformationCustomField[])

  const [errors, setErrors] = useState<Record<string, string>>({})
  const [values, setValues] = useState<Record<string, unknown>>(() =>
    (flatFields ?? []).reduce(
      (acc, cf) =>
        (acc = {
          ...acc,
          ...(getInitialFieldValue(cf, signup.data.additionalInformation) !== undefined && { [cf.fieldName]: getInitialFieldValue(cf, signup.data.additionalInformation) }),
        }),
      {} as Record<string, unknown>
    )
  )

  const resetFieldError = (fieldName: string) => setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: '' }))
  const setFieldError = (fieldName: string, error: string) =>
    setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: error }))

  const cfConfig = useMemo(() => {
    const configCopy = JSON.parse(JSON.stringify(customFieldsConfig)) as AdditionalInformationConfig

    const flattenedFields = configCopy?.groups?.reduce((acc, groupCur) => {
      groupCur.rows.map((row) =>
        row.map((cf) => {
          acc.push(cf)
        })
      )
      return acc
    }, [] as AdditionalInformationCustomField[])

    const config = {
      ...configCopy,
      groups: configCopy.groups.map(group => ({
        ...group,
        rows: group.rows?.map(row => {
          return row.filter(subRow => {
            if (subRow.showIfTrue) {
              const ifTrueParent = flattenedFields?.find(ff => ff.fieldName === subRow.showIfTrue) as
                | AdditionalInformationCustomFieldSelect
                | undefined

              const shouldShow = ifTrueParent?.trueIfValueMatch
                ? values[subRow.showIfTrue] === ifTrueParent.trueIfValueMatch
                : values[subRow.showIfTrue]

              if (!shouldShow) {
                resetFieldError(subRow.fieldName)
                setValues(prevValues => {
                  try {
                    delete prevValues[subRow.fieldName]
                  } catch (error) {
                    //
                  }
                  return prevValues
                })
              }
              return shouldShow
            }
            if (subRow.showIfFalse) {
              const shouldShow = !values[subRow.showIfFalse]
              if (!shouldShow) {
                resetFieldError(subRow.fieldName)
                setValues(prevValues => {
                  try {
                    delete prevValues[subRow.fieldName]
                  } catch (error) {
                    //
                  }
                  return prevValues
                })
              }
              return shouldShow
            }

            return true
          })
        }).filter(arr => arr.length)
      }))
    }

    return config
  }, [customFieldsConfig,  values])

  const validateField = (value: string, customField: AdditionalInformationCustomField) => {
    if (!customField.required && !value) return ''

    switch (customField.type) {
      case AdditionalInformationCustomFieldType.TEXT:
        if (hasIllegalCharacters(value)) return 'Invalid characters'
        return customField.required && (value ?? []).length === 0 ? settings.userChapter.userEdit.errors.requiredField : ''

      case AdditionalInformationCustomFieldType.NUMBER:
        if (hasIllegalCharacters(value)) return 'Invalid characters'
        return !value || value === '0' ? settings.userChapter.userEdit.errors.requiredField : ''

      // Not running regex for illegal chars in select, BE validation
      case AdditionalInformationCustomFieldType.SELECT:
        return !value || value === customField.emptyDropdownValue
          ? settings.userChapter.userEdit.errors.requiredField
          : ''

      case AdditionalInformationCustomFieldType.PHONE: {
        try {
          if (signup.data.session?.country.toUpperCase() === 'GLOBAL') {
            return phoneRegex.test(value)
          } else {
            const number = phoneUtil.parseAndKeepRawInput(value, signup.data.session?.country.toUpperCase() || 'SE')
            const validNumber = phoneUtil.isValidNumber(number) && phoneRegex.test(value)
            return validNumber ? '' : settings.userChapter.userEdit.errors.phoneError
          }
        } catch {
          return settings.userChapter.userEdit.errors.phoneError
        }
      }

      case AdditionalInformationCustomFieldType.EMAIL:
        return EmailValidator.validate(value) ? '' : settings.userChapter.userEdit.errors.emailError

      default:
        if (hasIllegalCharacters(value)) return 'Invalid characters'
        return ''
    }
  }

  const validateFieldValue = (customField: AdditionalInformationCustomField, value: string) => {
    const error = validateField(value, customField)
    if (!error) {
      resetFieldError(customField.fieldName)
      return true
    } else {
      setFieldError(customField.fieldName, error)
      return false
    }
  }

  const onChange = (customField: AdditionalInformationCustomField, value: unknown) => {
    setValues(prevValues => {
      const vals = JSON.parse(JSON.stringify(prevValues))
      if (value === undefined) {
        try {
          delete vals[customField.fieldName]
        } catch (error) {
          //
        }
      } else {
        vals[customField.fieldName] = value
      }
      return vals
    })
    resetFieldError(customField.fieldName)
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const hasError = cfConfig.groups.reduce((acc, groupCur) => {
      groupCur.rows.map((row) =>
        row.map(cf => {
          acc = !validateFieldValue(cf, values[cf.fieldName] as string) || acc
        })
      )
      return acc
    }, false)

    if (hasError) {
      return
    }

    signup.actions.setAdditionalInformation(values)

    navigate(AppRoutes.HOME)
  }

  const renderInput = (cf: AdditionalInformationCustomField) => {
    switch (cf.type) {
      case AdditionalInformationCustomFieldType.TEXT:
      case AdditionalInformationCustomFieldType.NUMBER:
      case AdditionalInformationCustomFieldType.EMAIL:
      case AdditionalInformationCustomFieldType.PHONE: {
        return (
          <FormControl component="fieldset" fullWidth={true}>
            <FormLabel component="legend">{cf.label}</FormLabel>
            <TextField
              data-testid={`customfield-${cf.fieldName}`}
              name={cf.fieldName}
              fullWidth={true}
              type={cf.type}
              value={values[cf.fieldName]}
              onChange={(e) => onChange(cf, e.target.value)}
              onBlur={(e) => validateFieldValue(cf, e.target.value)}
              error={!!errors[cf.fieldName]}
              helperText={errors[cf.fieldName]}
              inputProps={cf.type === 'number' ? { min: 0, max: 1000000000 } : { maxLength: 500 }}
            />
          </FormControl>
        )
      }

      case AdditionalInformationCustomFieldType.SELECT: {
        return (
          <FormControl component="fieldset" fullWidth={true}>
            <FormLabel component="legend">{cf.label}</FormLabel>
            <Select
              labelId={cf.fieldName}
              id={cf.fieldName}
              value={values[cf.fieldName] || cf.emptyDropdownValue}
              onChange={(e) => onChange(cf, e.target.value === cf.emptyDropdownValue ? undefined : e.target.value)}
              error={!!errors[cf.fieldName]}
              className={classes.unsetWhiteSpace}
            >
              {cf.emptyDropdownValue && (!values[cf.fieldName] || !cf.required) && (
                <MenuItem key={`select-${cf.fieldName}--1`} value={cf.emptyDropdownValue}>
                  {cf.emptyDropdownValue}
                </MenuItem>
              )}
              {cf.values.map((selectValue, selectIndex) => (
                <MenuItem
                  key={`select-${cf.fieldName}-${selectIndex}`}
                  value={selectValue}
                  className={classes.unsetWhiteSpace}
                >
                  {selectValue}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )
      }

      case AdditionalInformationCustomFieldType.RADIO: {
        if (!cf.fieldName?.length) {
          return null
        }
        return (
          <FormControl component="fieldset" fullWidth={true}>
            <FormLabel component="legend">{cf.label}</FormLabel>
            <RadioGroup
              row
              aria-label={cf.fieldName}
              name={cf.fieldName}
              style={{ justifyContent: 'center' }}
              value={values[cf.fieldName]}
              onChange={(e) => onChange(cf, e.target.value)}
            >
              {cf.values.map((radioValue, radioIndex) => (
                <FormControlLabel
                  key={`radio-${cf.fieldName}-${radioIndex}`}
                  value={radioValue}
                  control={<Radio />}
                  label={radioValue}
                />
              ))}
            </RadioGroup>
          </FormControl>
        )
      }

      case AdditionalInformationCustomFieldType.SWITCH: {
        return (
          <FormControlLabel
            control={<Switch />}
            label={cf.label}
            checked={values[cf.fieldName] as boolean}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange={(e) => onChange(cf, (e.target as any).checked)}
          />
        )
      }

      default:
        return null
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column" spacing={2} data-testid="UserEditContainer">
        <Grid item xs={8}>
          <Typography variant="h5" component="h2" className={classes.bigTitle}>
            {cfConfig.header}
          </Typography>
        </Grid>
        <Grid item>
          {cfConfig.groups.map((group, groupIndex) => (
            <Grid
              item
              key={`group-${groupIndex}`}
              style={{ alignItems: 'center', justifyContent: 'center', marginBottom: 30 }}
            >
              {!!group.header && (
                <Typography variant="body1" component="h2">
                  {group.header}
                </Typography>
              )}
              {group.rows.map((row, rowIndex) => (
                <Grid
                  item
                  key={`cr-${rowIndex}`}
                  style={{
                    alignSelf: 'center',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    columnGap: 15,
                    marginTop: 10,
                  }}
                >
                  {row.map((cf, colIndex) => (
                    <div key={`cf-${colIndex}`} style={{ flexGrow: 1, width: '100%' }}>
                      {renderInput(cf)}
                    </div>
                  ))}
                </Grid>
              ))}
            </Grid>
          ))}
        </Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Grid item>
              <Button
                variant="contained"
                data-testid="AdditionalInformationSaveButton"
                color="secondary"
                type="submit"
                fullWidth={true}
                disabled={!!Object.values(errors).find((e) => !!e)}
              >
                {settings.userChapter.userEdit.buttons.continue}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}

export default AdditionalInformationEdit
