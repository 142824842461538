import React from 'react'
import { useSignup } from '../../signupContext'
import { settings } from '../../resources'
import { Grid, PropTypes, Typography } from '@material-ui/core'
import { AppRoutes } from '../../application/types'
import useNavigateReplace from '../../hooks/useNavigateReplace';
import useEditChapterStyles from '../../styles/useEditChapterStyles'

const CompanyChapter = ({ bigTitle = true }: Record<string, boolean>) => {
  const signup = useSignup()
  const navigate = useNavigateReplace()
  const classes = useEditChapterStyles()

  const resetCompanyEnabled = !!signup.data.session?.merchantconfig?.signup_enable_reset_company_button
  const editAddressEnabled = (((signup.data.company?.address  ?? []).length !== 1) || signup.data.session?.merchantconfig?.signup_allowed_manual_addresses?.includes("billing"))

  const resetCompany = () => {
    signup.actions.resetCompanyData()
    navigate(AppRoutes.CHAPTER_COMPANY_COMPANY_LOOKUP)
  }

  const editSelectedCompanyAddress = () => {
    navigate(AppRoutes.CHAPTER_COMPANY_ADDRESS_EDIT)
  }

  if (!signup.data.selectedCompanyAddress) {
    return null
  }

  const renderResetCompany = (align: PropTypes.Alignment) => (
    <div
      className={classes.editButton}
      onClick={resetCompany}
      style={{ display: 'flex', alignItems: align === 'left' ? 'flex-start' : 'flex-end', justifyContent: align === 'left' ? 'flex-start' : 'flex-end', width: align === 'left' ? '100%' : '50%' }}>
      <Typography color="textPrimary" variant="body2" align={align} className={classes.changeButton}>
        {settings.companyChapter.companyOverview.editCompany}
      </Typography>
    </div>
  )

  return (
    <Grid container>

      <div style={{ display: 'flex',  flexDirection: 'row', width: '100%' }}>
        {/* Left col (Header + optional "Reset company") */}
        <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
          <div >
            <Typography variant="h5" component="h2" className={bigTitle ? classes.bigTitle : classes.smallTitle}>
              {settings.companyChapter.companyOverview.header}
            </Typography>
          </div>
            {(resetCompanyEnabled && editAddressEnabled) && renderResetCompany('left')}
        </div>
        {/* Right col */}
        {editAddressEnabled && (
          <div className={classes.editButton} onClick={editSelectedCompanyAddress} style={{ display: 'flex',  alignItems: 'flex-end', justifyContent: 'flex-end', width: '50%' }}>
            <Typography color="textPrimary" variant="body2" align="right" className={classes.changeButton}>
              {settings.companyChapter.companyOverview.editAddress}
            </Typography>
          </div>
        )}
        {(resetCompanyEnabled && ! editAddressEnabled) && renderResetCompany('right')}
      </div>

      {/* Content */}
      <Grid container className={classes.roundedBorder} data-testid="CompanyOverviewPreviewContainer">
        <Grid item xs={7}>
          <Typography color="textPrimary" align="left" className={classes.titleText}>
            {signup.data.company?.companyName}
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography color="textPrimary" align="right" className={classes.cinText}>
            {signup.data.approvedCin}
          </Typography>
        </Grid>
        <Typography color="textPrimary" variant="body2" align="left">
          {signup.data.selectedCompanyAddress.streetaddress},&nbsp;
        </Typography>
        <Typography color="textPrimary" variant="body2" align="left">
          {signup.data.selectedCompanyAddress.zip} {signup.data.selectedCompanyAddress.city}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default CompanyChapter
