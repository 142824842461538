import React, { FC, useEffect, useState } from 'react'
import BankIDMenu from './BankIDMenu'
import { BankIdProvider, useBankId } from './Context'
import { settings } from "../../resources"
import fetchr from "../../application/fetchr"
import { useSignup } from "../../signupContext"
import { BankIdCollect } from './types'


interface BankIDProps {
  onSuccess: (collectState: BankIdCollect) => Promise<void>
  onFailure?: (collectState: BankIdCollect) => void
  onCancel?: () => void
}

const BankId: FC<BankIDProps> = ({ onSuccess, onFailure }) => {
  const { collectState } = useBankId()

  const [loading, setLoading] = useState(false)

  const onStatusChange = async () => {
    try {
      if (collectState?.status === 'complete') {
        setLoading(true)
        await onSuccess?.(collectState)
      } else if (collectState?.status === 'failed') {
        setLoading(true)
        onFailure?.(collectState)
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    onStatusChange()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectState?.status])

  return (
    <div
      data-testid="BankIdContainer"
      style={{
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
      }}
    >
      <BankIDMenu loading={loading} />
    </div>
  )
}

interface WrapperProps {
  onSuccess: (collectState: BankIdCollect) => Promise<void>
  onFailure?: () => void
  onCancel?: () => void
  translations: Record<string, string>
}

const BankIdWrapper: FC<WrapperProps> = ({ onSuccess, onFailure, onCancel }) => {
  const signup = useSignup()

  const bankidProps: any = {
    translations: { ...settings.bankid, ...(signup.data.validateSignatories && { authHeader: settings.bankid.signatoryAuthHeader }) },
    authRequest: (data: Record<string, unknown>) => fetchr(signup.data.url + "/api/bankid/signup/auth", signup.data.token).post(data).then(res => res.json()).catch(async e => { throw (await e.json()) }),
    pollRequest: (id: string) => fetchr(signup.data.url + `/api/bankid/signup/collect/${id}`, signup.data.token).get().then(res => res.json()).catch(async e => { throw (await e.json()) }),
    cancelRequest: (id: string) => fetchr(signup.data.url + `/api/bankid/signup/cancel/${id}`, signup.data.token).get().then(() => { return }).catch(async e => { throw (await e.json()) }),
  }

  return (
    <BankIdProvider {...bankidProps}>
      <BankId onSuccess={onSuccess} onFailure={onFailure} onCancel={onCancel} />
    </BankIdProvider>
  )
}

export default BankIdWrapper
