import React, { useEffect } from 'react'
import './App.css'
import { useSignup } from './signupContext'
import { AppRoutes } from "./application/types"
import useNavigateReplace from './hooks/useNavigateReplace'
import { useLocation } from 'react-router-dom'

const alwaysAllowedRoutes = [
  AppRoutes.CHAPTER_COMPANY_COMPANY_LOOKUP,
  AppRoutes.SIGNUP_ERROR,
  AppRoutes.SIGNUP_SUCCESS,
  AppRoutes.CHAPTER_COMPANY_CREDIT_TERMS,
]

function NavigationReset(): JSX.Element {
  const signup = useSignup()
  const navigate = useNavigateReplace()
  const location = useLocation();

  useEffect(() => {
    if (!signup.data.approvedCin && !alwaysAllowedRoutes.includes(location.pathname as AppRoutes)) {
      navigate(AppRoutes.CHAPTER_COMPANY_COMPANY_LOOKUP)
    }

  }, [navigate, signup.data.approvedCin, location.pathname])

  return (<></>);
}

export default NavigationReset
