import React, { useEffect, useState } from 'react'
import { settings } from '../../resources'
import { CompanyLookup } from '../../application/types'
import IconTextInput from './IconTextInput'
import BusinessIcon from '@material-ui/icons/Business'
import { useSignup } from '../../signupContext'

interface Props {
  fetching: boolean
  setError: (hasError: boolean) => void,
  setCompanyLookup: (companyLookup: CompanyLookup) => void,
}

const illegalCharacters = new RegExp('^[^\\[\\]<>{}\\\\()`;]*$')
const hasIllegalCharacters = (str: string) => !illegalCharacters.test(str)

const VatNoEdit = (props: Props) => {
  const { fetching, setError, setCompanyLookup } = props
  const signup = useSignup()

  const [vatNoValidationError, setVatNoValidationError] = useState('')

  useEffect(() => {
    validateVatNo(signup.data.companyLookup.vatNo ?? '' as string)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const formatVatNo = (vatNo: string) => {
    const value = vatNo.trim()
    return value.replace(/-/g, '').replace(/ /g, '')
  }

  const validateVatNo = (inputVatNo: string) => {
    const formattedVatNo = formatVatNo(inputVatNo)

    const vatNoIsValid = (formattedVatNo.length > 6 && formattedVatNo.length < 14 && !hasIllegalCharacters(formattedVatNo))

    if (vatNoIsValid) {
      setCompanyLookup({
        vatNo: formattedVatNo,
      })
      setVatNoValidationError('')
      setError(false)
    } else {
      setError(true)

      if (formattedVatNo?.length) {
        setVatNoValidationError(settings.commonErrors.vatError)
      }
    }
  }

  const country = signup.data.session?.country.toLowerCase()

  return (
    <div style={{ marginTop: 20, marginBottom: 20 }}>
      <IconTextInput
        validationError={vatNoValidationError}
        onChange={e => validateVatNo(e.target.value)}
        disabled={fetching}
        defaultValue={signup.data.companyLookup.vatNo}
        name={"company_vat_number" + (country === 'se' ? '' : `_${country}`)}
        label={settings.companyChapter.cinEdit.vatLabel}
        testId="VatNoCollectionInput"
        Icon={<BusinessIcon />}
        autoFocus={true}
      />
    </div>
  )
}

export default VatNoEdit
