import React, { useEffect } from "react"
import BankIdWrapper from "./BankIdWrapper"
import { settings } from '../../resources'
import useResizeObserver from "use-resize-observer"

interface Props {
  onClose: (success: boolean) => void
}

const ExternalAuthModal = ({ onClose }: Props): JSX.Element => {
  const MIN_HEIGHT = 170
  
  const { ref, height = MIN_HEIGHT } = useResizeObserver<HTMLDivElement>()

  useEffect(() => {
    const newHeight = height > MIN_HEIGHT ? height : MIN_HEIGHT
    window.parent.postMessage({ messageType: 'height_signup', messageContent: newHeight }, '*')
  }, [height])
  
  const handleSuccess = () => {
    onClose(true)
  }

  const handleCancel = () => {
    onClose(false)
  }

  return (
    <div ref={ref} style={{ position: 'absolute', width: '100%', top: 0, left: 0, backgroundColor: 'white', zIndex: 100 }}>
        <BankIdWrapper translations={settings.bankid} onSuccess={handleSuccess} onFail={handleCancel} onCancel={handleCancel} defaultOtherDeviceCollection='pnr' />
    </div>
  )
}

export default ExternalAuthModal
