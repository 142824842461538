import React, { FC, useEffect } from 'react'
import { Typography } from '@material-ui/core'
import { useBankId } from './Context'
import {
  BankIdFailedHintCode,
  BankIdHintCode,
  BankIdOtherEnum,
  BankIdPendingHintCode,
  BankIdStatus,
  Rfa,
} from './types'

export const getByHintCode = (
  hintCode: BankIdHintCode = BankIdPendingHintCode.NO_CLIENT,
  status = BankIdStatus.PENDING,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  translations: any,
): Rfa => {
  let hintMessage

  switch (hintCode) {
    case BankIdFailedHintCode.CANCELLED:
      hintMessage = translations.rfa.rfa3
      break

    case BankIdOtherEnum.ALREADY_IN_PROGRESS:
      hintMessage = translations.rfa.rfa4
      break

    case BankIdOtherEnum.REQUEST_TIMEOUT:
    case BankIdOtherEnum.MAINTENANCE:
    case BankIdOtherEnum.INTERNAL_ERROR:
      hintMessage = translations.rfa.rfa5
      break

    case BankIdFailedHintCode.USER_CANCEL:
      hintMessage = translations.rfa.rfa6
      break
    case BankIdFailedHintCode.BRIQ_INCORRECTSIGNATORY:
      hintMessage = translations.incorrectSignee
      break

    case BankIdFailedHintCode.EXPIRED_TRANSACTION:
      hintMessage = translations.rfa.rfa8
      break

    case BankIdPendingHintCode.USER_SIGN:
      hintMessage = translations.rfa.rfa9
      break

    case BankIdFailedHintCode.CERTIFICATE_ERR:
      hintMessage = translations.rfa.rfa16
      break

    case BankIdFailedHintCode.START_FAILED:
      hintMessage = translations.rfa.rfa17b
      break

    case BankIdPendingHintCode.OUTSTANDING_TRANSACTION:
    case BankIdPendingHintCode.NO_CLIENT:
    case undefined:
      hintMessage = translations.pleaseScanQr
      break

    default:
      hintMessage = translations.rfa.rfa22
  }

  return {
    text: hintMessage,
    status: status,
  }
}

const RfaMessage: FC = () => {
  const { collectState, translations, rfa, setRfa, startState, loading } = useBankId()

  // Set initial rfa text when starting polling, before collectState exists
  useEffect(() => {
    if (startState?._id && !collectState) {
      setRfa({
        text: translations.pleaseScanQr,
        status: BankIdStatus.PENDING,
      })
    }
  }, [startState?._id, setRfa, translations, collectState])

  // Update rfa text on polling result
  useEffect(() => {
    if (collectState?.hintCode) {
      setRfa(getByHintCode(collectState?.hintCode, collectState?.status, translations))
    }
  }, [collectState?.hintCode, setRfa, collectState, translations])

  return (
    <div data-testid="BankIdRfa">
      <Typography color="textPrimary" variant="body2" align="center">{loading ? translations.loading : rfa ? rfa.text : translations.pleaseScanQr}</Typography>
    </div>
  )
}

export default RfaMessage
