import React, { useState } from 'react'
import EnumRadio from './EnumRadio'
import { useSignup } from '../../signupContext'
import { CUSTOMER_TYPE } from '../../application/types'
import { Grid, Typography } from '@material-ui/core'
import { settings } from '../../resources'
import fetchr from '../../application/fetchr'

const CustomerTypeSwitcher = () => {
  const signup = useSignup()

  const [loading, setLoading] = useState(false)

  const onChange = async (customerType: CUSTOMER_TYPE) => {
    setLoading(true)
    try {
      const response = await fetchr(signup.data.url + '/api/signup/customer-type', signup.data.token).post({
        customerType,
      })
      const body = await response.json()
      signup.actions.resetCompanyLookup()
      signup.actions.setSession(body.session)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Grid container direction="column" spacing={2} style={{ paddingTop: 8 }}>
      <Grid item>
        <Typography color="textPrimary" variant="body1" align="center">
          {settings.companyChapter.customerTypeSwitcher.registeringAs}
        </Typography>
      </Grid>
      <Grid item>
        <EnumRadio
          disabled={loading}
          loading={loading}
          data={[
            { value: CUSTOMER_TYPE.BUSINESS, label: settings.companyChapter.customerTypeSwitcher.business },
            { value: CUSTOMER_TYPE.CONSUMER, label: settings.companyChapter.customerTypeSwitcher.consumer },
          ]}
          onChange={(value) => onChange(value as CUSTOMER_TYPE)}
          selectedValue={signup.data.session?.customerType}
        />
      </Grid>
    </Grid>
  )
}

export default CustomerTypeSwitcher
