import React from "react"
import { settings } from "../../resources"
import fetchr from "../../application/fetchr"
import { useSignup } from "../../signupContext"
import { Button } from "@material-ui/core"
import johnny from "../../johnny"
import NewBankId from './NewBankId/NewBankId'

interface Props {
  onSuccess: () => void
  onFail: () => void
  onCancel: () => void
  defaultOtherDeviceCollection: string
  translations: Record<string, string>
}

const BankIdWrapper = ({ onSuccess, onFail, onCancel, defaultOtherDeviceCollection }: Props) => {
  const signup = useSignup()

  const authRequest = React.useCallback(
    (data: Record<string, unknown>) =>
      fetchr(signup.data.url + '/api/bankid/signup/auth', signup.data.token)
        .post(data)
        .then((res) => res.json())
        .catch(async (e) => {
          throw await e.json()
        }),
    [signup.data.token, signup.data.url]
  )
  const translations = React.useMemo(
    () => ({
      ...settings.bankid,
      ...(signup.data.validateSignatories && { authHeader: settings.bankid.signatoryAuthHeader }),
    }),
    [signup.data.validateSignatories]
  )

  const bankidProps = {
    onSuccess,
    onFail,
    onCancel,
    defaultOtherDeviceCollection,
    translations,
    authRequest,
    pollRequest: (id: string) => fetchr(signup.data.url + `/api/bankid/signup/collect/${id}`, signup.data.token).get().then(res => res.json()).catch(async e => { throw (await e.json()) }),
    cancelRequest: (id: string) => fetchr(signup.data.url + `/api/bankid/signup/cancel/${id}`, signup.data.token).get().then(() => { return }).catch(async e => { throw (await e.json()) }),
  }

  const simulateBankID = async () => {
    await fetchr(signup.data.url + '/api/bankid/signup/simulate', signup.data.token).post({})
        .then(res => res.json())
        .then(onSuccess)
  }

  return (
    <>
      {!!johnny.getTestMode() && (
        <>
          <Button variant="contained" color="primary" type="button" onClick={simulateBankID}>
            Simulate BankID
          </Button>
        </>
      )}
      <NewBankId {...bankidProps} />
    </>
  )
}

export default BankIdWrapper
