import React, { useEffect } from 'react'
import fetchr from './application/fetchr'
import { AppRoutes, SIGNUP_SESSION_STATE } from './application/types'
import useNavigateReplace from './hooks/useNavigateReplace'
import johnny from './johnny'
import { useSignup } from './signupContext'

const InitializeApp = () => {
  const signup = useSignup()
  const navigate = useNavigateReplace()

  useEffect(() => {
    const token = johnny.getToken()
    token && signup.actions.setToken(token)

    if (token && !signup.data.session) {
      fetchr(signup.data.url + '/api/signup/session', token)
        .get()
        .then(res => res.json())
        .then(session => {
          signup.actions.setSession(session)

          if (session.state === SIGNUP_SESSION_STATE.COMPLETED) {
            navigate(AppRoutes.SIGNUP_SUCCESS)
          } else if (session.state === SIGNUP_SESSION_STATE.MERCHANT_HOOK_REJECTED) {
            navigate(AppRoutes.SIGNUP_ERROR)
          }
        })
        .catch(() => {
          navigate(AppRoutes.SIGNUP_ERROR)
        })
    }

  }, [navigate, signup.actions, signup.data.session, signup.data.url])

  return (<></>);
}

export default InitializeApp
