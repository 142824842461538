type CB = (data: Record<string, unknown>) => unknown

const subscribers: Record<string, Array<CB>> = {}
const oncesubscribers: Record<string, Array<CB>> = {}

const publish = (eventName: string, data: Record<string, unknown>): void => {
    if (!Array.isArray(oncesubscribers[eventName])) {

        return
    }
    if (subscribers[eventName]) {
        subscribers[eventName].forEach((callback) => {

            callback(data)
        })
    }
    if (oncesubscribers[eventName]) {
        oncesubscribers[eventName].forEach((callback) => {

            callback(data)
        })
    }
    oncesubscribers[eventName] = []
}

const subscribe = (eventName: string, callback: CB): void => {
    if (!Array.isArray(subscribers[eventName])) {
        subscribers[eventName] = []
    }
    subscribers[eventName].push(callback)
}
const subscribeOnce = (eventName: string, callback: CB): void => {
    if (!Array.isArray(oncesubscribers[eventName])) {
        oncesubscribers[eventName] = [callback]
    }
    else {
        oncesubscribers[eventName].push(callback)
    }

}
const unsubscribeAll = (eventName: string): void => {
    if (Array.isArray(subscribers[eventName])) {
        subscribers[eventName] = []
    }
    if (Array.isArray(oncesubscribers[eventName])) {
        oncesubscribers[eventName] = []
    }

}

const listen = () => {
    window.addEventListener("message", function (e) {
        if (e.data.messageType) {
            publish(e.data.messageType, e.data)
        }
    })

}
listen();

export default {
    publish,
    subscribe,
    unsubscribeAll,
    subscribeOnce
}
