import { Backdrop, Button, Grid, CircularProgress } from '@material-ui/core'
import React, { useState } from 'react'
import { useSignup } from '../../signupContext'
import { AppRoutes, SIGNUP_RESULT, SIGNUP_SESSION_STATE, VALIDATION_FLOW, MerchantUserErrors } from '../../application/types'
import { settings } from '../../resources'
import fetchr from '../../application/fetchr'
import useAsyncInterval from '../../hooks/useAsyncInterval'
import { makeStyles } from '@material-ui/core/styles'
import subbie from '../../subbie'
import useNavigateReplace from '../../hooks/useNavigateReplace'
import ExternalAuthModal from './ExternalAuthModal'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: 'transparent',
  },
  spinnWrapper: {
    position: "absolute",
    marginTop: theme.spacing(1)
  },
  confirmButton: {
    position: "relative"
  }
}))

const POLLING_INTERVAL = 1000
const POLLING_TIMEOUT = 12000

const FinalizeSignup = () => {
  const signup = useSignup()
  const navigate = useNavigateReplace()
  const [isWaiting, setIsWaiting] = useState(false)
  const [delay, setDelay] = useState<number | null>(null)
  const [pollingAttempts, setPollingAttempts] = useState<number>(0)
  const [pollingDuration, setPollingDuration] = useState<number>(0)
  const classes = useStyles()
  const merchantPollingTime = signup.data.session?.merchantconfig?.clientPurchaseApprovalTimeout ?? POLLING_TIMEOUT
const merchantPollingAttempts = merchantPollingTime / 1000

  const startPolling = () => {
    setPollingAttempts(0)
    setPollingDuration(0)
    setDelay(POLLING_INTERVAL)
  }
  const stopPolling = () => setDelay(null)

  const handleSuccess = () => {
    if (signup.data.session?.merchanturls?.redirecturl && window.top) {
      window.top.location.href = signup.data.session.merchanturls.redirecturl
    } else {
      navigate(AppRoutes.SIGNUP_SUCCESS)
    }
  }

  const onClientApiValidatedResponse = async ({ response }: Record<string, unknown>) => {
    const validatedResponse = await fetchr(signup.data.url + '/api/signup/validated', signup.data.token)
      .post(response as Record<string, unknown>)
      .catch(() => ({
        status: 400
      })
      )

    if (validatedResponse.status < 200 || validatedResponse.status > 299) {
      navigate(AppRoutes.SIGNUP_ERROR)
      return
    }

    startPolling()
  }

  const setSignupResult = (signupResult: SIGNUP_RESULT) => {
    stopPolling()

    switch (signupResult) {

      case SIGNUP_RESULT.SUCCESS: {
        handleSuccess()
        break
      }
      case SIGNUP_RESULT.INVALID_COMPANY:
        navigate(AppRoutes.CHAPTER_COMPANY_COMPANY_LOOKUP)
        break
      case SIGNUP_RESULT.INVALID_USER_FIELDS:
        navigate(AppRoutes.CHAPTER_USER_EDIT)
        break

      case SIGNUP_RESULT.UNKNOWN_ERROR:
      case SIGNUP_RESULT.TIMEOUT:
        navigate(AppRoutes.SIGNUP_ERROR)
        break

      default:
        break
    }
  }

  useAsyncInterval(async () => {
    const t0 = performance.now()

    let pollingRes
    try {
      pollingRes = await (await fetchr(signup.data.url + '/api/signup/poll-webhook-response', signup.data.token).get()).json()
    }
    catch (e: any) {
      if (e.status === 404) {
        setSignupResult(SIGNUP_RESULT.UNKNOWN_ERROR)
      }
      // TODO more error handling, retrying on other errors for now
    }

    if (pollingRes) {
      switch (pollingRes.state) {
        case SIGNUP_SESSION_STATE.MERCHANT_HOOK_PENDING:
          break

        case SIGNUP_SESSION_STATE.COMPLETED:
          // todo final client approval?
          setSignupResult(SIGNUP_RESULT.SUCCESS)
          break

        case SIGNUP_SESSION_STATE.MERCHANT_HOOK_REVALIDATE:

          signup.actions.setMerchantUserErrors(pollingRes.errors)
          if (pollingRes.errors.find((errorObject: MerchantUserErrors) => errorObject.errorField === 'cin')) {
            setSignupResult(SIGNUP_RESULT.INVALID_COMPANY)
          }
          else {
            setSignupResult(SIGNUP_RESULT.INVALID_USER_FIELDS)
          }

          break

        case SIGNUP_SESSION_STATE.MERCHANT_HOOK_REJECTED:
        default:
          setSignupResult(SIGNUP_RESULT.UNKNOWN_ERROR)
          break
      }
    }

    const t1 = performance.now()
    const requestDuration = t1 - t0

    setPollingDuration((dur) => dur + requestDuration + POLLING_INTERVAL)
    setPollingAttempts((attempts) => attempts + 1)

    if (pollingAttempts >= merchantPollingAttempts || pollingDuration > merchantPollingTime) {
      setSignupResult(SIGNUP_RESULT.TIMEOUT)
    }
  }, delay)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleConfirmSuccess = (validationResponse: any) => {
    if (validationResponse.status !== 204) {
      navigate(AppRoutes.SIGNUP_ERROR)
      return validationResponse
    }

    switch (signup.data.session?.validationFlow) {
      case VALIDATION_FLOW.CLIENT_API:
        subbie.subscribeOnce('signup_validated', onClientApiValidatedResponse)
        window.parent.postMessage({ messageType: 'event', eventName: 'signup_data', messageContent: signup.data }, '*')
        break

      case VALIDATION_FLOW.NONE:
        handleSuccess()
        break

      case VALIDATION_FLOW.WEBHOOK:
      default:
        startPolling()
        break
    }

    return validationResponse
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleConfirmFailure = (error: any) => {
    if (error.status === 400 && error?.body?.code === 'external_auth_required') {
      signup.actions.setExternalAuthModal(error.body?.requiredAuth, error?.body?.validateSignatories)
    } else {
      navigate(AppRoutes.SIGNUP_ERROR)
    }
  }

  const confirmSignup = async () => {
    setIsWaiting(true)

    fetchr(signup.data.url + '/api/signup/validate', signup.data.token)
      .post({
        orgnr: signup.data.approvedCin,
        addressId: signup.data.selectedCompanyAddress?._id,
        postalAddressId: signup.data.selectedPostalAddress?._id,
        shippingAddressId: signup.data.selectedShippingAddress?._id,
        user: signup.data.user,
        customFields: signup.data.additionalInformation,
        signupNewsletter: !!signup.data.newsletter,
      })
      .then(handleConfirmSuccess)
      .catch(e => e.json().then(handleConfirmFailure))
  }

  if (signup.data.external_auth) {
    return (
      <ExternalAuthModal
        onClose={(success) => {
          setIsWaiting(false)
          signup.actions.setExternalAuthModal('', false)
          if (success) {
            confirmSignup()
          }
        }}
      />
    )
  }

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item>
        <Button
          className={classes.confirmButton}
          onClick={confirmSignup}
          data-testid="FinalizeSignupButton"
          variant="contained"
          color="secondary"
          type="submit"
          disabled={isWaiting || (signup.data.session?.merchantconfig?.signup_terms?.enabled && !signup.data.termsAccepted)}
        >
          {signup.data.session?.merchantconfig?.signupfinalButtonUseContinueText ? settings?.finalizeSignUp.buttons.continueSignup :  settings?.finalizeSignUp.buttons.completeSignup}
          {isWaiting && (
            <div className={classes.spinnWrapper}>
              <CircularProgress size="30px" />
            </div>
          )}
        </Button>
      </Grid>
      <Backdrop className={classes.backdrop} open={isWaiting} />
    </Grid>
  )
}

export default FinalizeSignup
