import React, { useEffect } from 'react'
import { Grid, makeStyles, Typography, Link } from '@material-ui/core'
import { settings } from '../../resources'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import { useSignup } from '../../signupContext'

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: theme.typography.h5.fontSize,
    fontWeight: 'bolder',
  },
  icon: {
    fontSize: '8rem',
  }
}))

const Success = () => {
  const signup = useSignup()
  const classes = useStyles()

  useEffect(() => {
    window.parent.postMessage({ messageType: 'event', eventName: 'signup_finalized', messageContent: 'success' }, '*')
  }, [])

  return (
    <Grid container spacing={0} direction="column" alignItems="center" justify="center">
      <Grid item>
        <Typography component="h1" className={classes.title}>
          {settings.signUpSuccess.header}
        </Typography>
      </Grid>
      <Grid>
        <CheckCircleOutlineIcon className={classes.icon} />
      </Grid>
      {!!signup.data.session?.merchantconfig?.signup_success_text && (
        <p style={{ paddingLeft: 20, paddingRight: 20 }}>{signup.data.session?.merchantconfig?.signup_success_text}</p>
      )}
      {!signup.data.session?.merchantconfig?.signup_success_text && (
        <div style={{ padding: 15 }}>
          <Link href="#" onClick={() => window.parent.postMessage({ messageType: 'reload_window' }, '*')}>
            {settings.signUpSuccess.newAccountLink}
          </Link>
        </div>
      )}
    </Grid>
  )
}

export default Success
