import { Grid, makeStyles } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles(() => ({
  power: {
    width: '180px',
    marginTop: '1em',
    height: '40px'
  },
}))

function PoweredByBriqpay() {
  const classes = useStyles()

  return (
    <Grid container direction="column" justify="center" alignContent="center" spacing={2} data-testid="PoweredByBriqContainer">
      <img className={classes.power} src="https://cdn.briqpay.com/static/logos/PoweredByBriqpayVertical.svg" alt="Powered by Briqpay" />
    </Grid>
  )
}

export default PoweredByBriqpay
