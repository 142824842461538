import { Grid, Typography } from '@material-ui/core'
import React from 'react'
import { settings } from '../../resources'
import { AppRoutes } from '../../application/types'
import useNavigateReplace from '../../hooks/useNavigateReplace';
import { useSignup } from '../../signupContext'
import useEditChapterStyles from '../../styles/useEditChapterStyles';

const UserOverview = ({ bigTitle = true }: Record<string, boolean>) => {
  const signup = useSignup()
  const navigate = useNavigateReplace()
  const classes = useEditChapterStyles()

  const editUserDetails = () => {
    navigate(AppRoutes.CHAPTER_USER_EDIT)
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container justify="center">
          <Grid item className={classes.changeButton} onClick={editUserDetails} xs={12}>
            <Grid container direction="row">
              <Grid item xs={8}>
                <Typography variant="h5" component="h2" className={bigTitle ? classes.bigTitle : classes.smallTitle}>
                  {settings.userChapter.userChapterOverView.header}
                </Typography>
              </Grid>
              <Grid item xs={4} className={classes.editButtonSmall}>
                <Typography color="textPrimary" variant="body2" align="right">
                  {settings.userChapter.userChapterOverView.edit}
                </Typography>
              </Grid>
            </Grid>
            <Grid container className={classes.roundedBorder} data-testid="UserOverviewPreviewContainer">
              <Grid item xs={12}>
                <Typography color="textPrimary" align="left" className={classes.titleText}>
                  {signup.data.user?.firstName} {signup.data.user?.lastName}
                </Typography>
              </Grid>
              <Typography color="textPrimary" variant="body2" align="left">
                {signup.data.user?.phone}, {signup.data.user?.email}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default UserOverview
