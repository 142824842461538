import React, { useState, useEffect } from 'react'
import { useSignup } from '../../signupContext'
import { settings } from '../../resources'
import { CompanyAddress, AppRoutes } from '../../application/types'
import { Button, Grid, TextField, Typography } from '@material-ui/core'
import useNavigateReplace from '../../hooks/useNavigateReplace'
import AddressDropDown from './AddressDropDown'
import fetchr from '../../application/fetchr'
import { ArrowBack } from '@material-ui/icons'

const illegalCharacters = new RegExp('^[^\\[\\]<>{}\\\\()`;]*$')
const hasIllegalCharacters = (str: string) => !illegalCharacters.test(str)

const CompanyAddressDropDown = () => {
  const signup = useSignup()
  const navigate = useNavigateReplace()
  const [address, setAddress] = useState(
    signup.data.selectedCompanyAddress?.streetaddress ?? signup.data.company?.address?.[0]?.streetaddress ?? ''
  )
  const [city, setCity] = useState(signup.data.company?.address?.[0]?.city ?? '')
  const [zip, setZip] = useState(signup.data.company?.address?.[0]?.zip ?? '')
  const [addressValidationError, setAddressValidationError] = useState('')
  const [cityValidationError, setCityValidationError] = useState('')
  const [zipValidationError, setZipValidationError] = useState('')
  const addressesToUse = JSON.parse(JSON.stringify(signup.data.company?.address))
  const [manualAddress, setManualSelect] = useState(!signup.data.company?.address?.length)
  const [selectedCompanyAddress, setSelectedCompanyAddress] = useState(signup.data.selectedCompanyAddress)

  const changeAddress = (address: string) => {
    if (address.length >= 1 && address.length < 100 && !hasIllegalCharacters(address)) {
      setAddressValidationError('')
      return true
    } else {
      setAddressValidationError(settings.commonErrors.addressError)
      return false
    }
  }

  useEffect(() => {
    if (
      signup.data.session?.merchantconfig?.signup_allowed_manual_addresses?.includes('billing') &&
      !addressesToUse?.find((e: { _id: string }) => e._id === 'own')
    ) {
      addressesToUse?.push({ _id: 'own', streetaddress: '', zip: '', city: '', hq: false })
    }
    if (signup.data?.session?.country?.toLowerCase() === 'global') {
      setManualSelect(true)
    }
  }, [
    addressesToUse,
    signup.data.session?.merchantconfig?.signup_allowed_manual_addresses,
    signup.data?.session?.country,
  ])

  const changeCity = (city: string) => {
    if (city.length >= 1 && city.length < 100 && !hasIllegalCharacters(city)) {
      setCityValidationError('')
      return true
    } else {
      setCityValidationError(settings.commonErrors.cityError)
      return false
    }
  }

  const changeZip = (zip: string) => {
    if (zip.length >= 3 && zip.length < 30 && !hasIllegalCharacters(zip)) {
      setZipValidationError('')
      return true
    } else {
      setZipValidationError(settings.commonErrors.zipError)
      return false
    }
  }

  const changeSelectedAddress = (newValue?: CompanyAddress | null) => {
    if (newValue?._id === 'own') {
      setManualSelect(true)
      setSelectedCompanyAddress(undefined)
      return
    } else {
      if (newValue) {
        setManualSelect(false)
        setAddress(newValue.streetaddress)
        setCity(newValue.city)
        setZip(newValue.zip)
        setAddressValidationError('')
        setCityValidationError('')
        setZipValidationError('')
        setSelectedCompanyAddress(newValue)
      } else {
        setAddress('')
        setCity('')
        setZip('')
        setAddressValidationError('')
        setCityValidationError('')
        setZipValidationError('')
        setSelectedCompanyAddress(undefined)
      }
    }
  }

  const submitAddress = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!changeAddress(address) || !changeCity(city) || !changeZip(zip)) {
      return
    }

    const inputAddress: Partial<CompanyAddress> = {
      streetaddress: address,
      city,
      zip,
      manualInput: manualAddress,
    }
    fetchr(`${signup.data.url}/api/signup/address`, signup.data.token)
      .post(inputAddress as unknown as Record<string, string | boolean>)
      .then((response) => response.json())
      .then((response) => {
        signup.actions.setSelectedCompanyAddress(response as CompanyAddress)
        navigate(signup.data.user?.firstName && signup.data.user?.lastName && signup.data.user?.email ? AppRoutes.HOME : AppRoutes.CHAPTER_USER_EDIT)
      })
      .catch(() => {
        //
      })
  }

  return (
    <>
      <div style={{ cursor: 'pointer' }} onClick={() => navigate(AppRoutes.CHAPTER_COMPANY_COMPANY_LOOKUP)}>
        <Grid container direction="row" spacing={2}>
          <Grid item>
            <ArrowBack />
          </Grid>
          <Grid item>
            <Typography variant="body1" component="h2" style={{ paddingBottom: 20, cursor: 'pointer' }}>
              {signup.data.company?.companyName}
            </Typography>
          </Grid>
        </Grid>
      </div>
      <form onSubmit={submitAddress}>
        <Grid container direction="column" spacing={3}>
          {!!signup.data.company?.address?.length && !manualAddress && (
            <Grid item>
              <AddressDropDown
                allowManual={signup.data.session?.merchantconfig?.signup_allowed_manual_addresses?.includes('billing')}
                addresses={addressesToUse}
                selectedAddress={selectedCompanyAddress}
                onChange={changeSelectedAddress}
                allowClear={true}
              />
            </Grid>
          )}

          {(!signup.data.company?.address?.length || manualAddress) && (
            <>
              <Grid item>
                <TextField
                  data-testid="CompanyAddress"
                  autoComplete="address"
                  name="address"
                  onChange={(e) => {
                    setAddress(e.target.value)
                    setAddressValidationError('')
                  }}
                  onBlur={(e) => changeAddress(e.target.value)}
                  error={!!addressValidationError}
                  helperText={addressValidationError}
                  defaultValue={signup.data.company?.address?.[0]?.streetaddress}
                  label={settings.commonTerms.address}
                  fullWidth={true}
                  value={address}
                />
              </Grid>
              <Grid item>
                <TextField
                  data-testid="CompanyAddressCity"
                  autoComplete="city"
                  name="city"
                  onChange={(e) => {
                    setCity(e.target.value)
                    setCityValidationError('')
                  }}
                  onBlur={(e) => changeCity(e.target.value)}
                  error={!!cityValidationError}
                  helperText={cityValidationError}
                  defaultValue={signup.data.user?.lastName}
                  label={settings.commonTerms.city}
                  fullWidth={true}
                  value={city}
                />
              </Grid>
              <Grid item>
                <TextField
                  data-testid="CompanyZip"
                  autoComplete="zip"
                  name="zip"
                  onChange={(e) => {
                    setZip(e.target.value)
                    setZipValidationError('')
                  }}
                  onBlur={(e) => changeZip(e.target.value)}
                  error={!!zipValidationError}
                  helperText={zipValidationError}
                  defaultValue={signup.data.user?.lastName}
                  label={settings.commonTerms.postalCode}
                  fullWidth={true}
                  value={zip}
                />
              </Grid>
            </>
          )}

          <Grid item>
            <Grid container direction="column" alignItems="center">
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  fullWidth={true}
                  disabled={!!addressValidationError || !!cityValidationError || !!zipValidationError}
                >
                  {settings.userChapter.userEdit.buttons.continue}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  )
}

export default CompanyAddressDropDown
