import React, { useContext, createContext, useState, useEffect, FC } from 'react'
import { getByHintCode } from './RfaMessage'
import { BankIdAuth, BankIdCollect, BankIdOtherEnum, BankIdStatus, Rfa } from './types'
import useAsyncInterval2 from '../../hooks/useAsyncInterval2'

interface Ctx {
  startState: BankIdAuth
  setStartState: any
  collectState: BankIdCollect
  setCollectState: any
  translations: any
  authRequest: any
  pollRequest: any
  cancelRequest: any
  reset: any
  cancel: any
  cancelling: any
  resetShallow: any
  startBankID: any
  rfa: any
  setRfa: any
  loading: any
  setLoading: any
}

const BankIdContext = createContext(undefined)

function useBankId(): Ctx {
  const context = useContext(BankIdContext)
  if (!context) {
    throw new Error(`useBankId must be used within a BankIdProvider`)
  }
  return context
}

const BankIdProvider: FC<Ctx> = ({
  translations,
  authRequest,
  pollRequest,
  cancelRequest,
  ...props
})  => {
  const [startState, setStartState] = useState<BankIdAuth>()
  const [collectState, setCollectState] = useState<Partial<BankIdCollect>>()
  const [rfa, setRfa] = useState<Rfa>()
  const [loading, setLoading] = useState(false)
  const [cancelling, setCancelling] = useState(false)

  const [pollRes, setPollRes] = useState(undefined)

  useEffect(() => {
    if (startState?._id && !loading && !cancelling && pollRes) {
      setCollectState(pollRes)
      setPollRes(undefined)
    }
  }, [startState?._id, pollRes, loading, cancelling])

  useAsyncInterval2(
    async () => {
      if (startState?._id && (!collectState || collectState.status === 'pending')) {
        pollRequest(startState?._id)
        .then((result: any) => {
          setLoading(false)
          setPollRes(result)
        })
        .catch((error: any) => {
          setLoading(false)
          setCollectState({ status: BankIdStatus.FAILED, hintCode: error.code || BankIdOtherEnum.INTERNAL_ERROR })
        })
      }
    },
    [startState?._id],
    1100
  )

  const reset = () => {
    setStartState(undefined)
    setCollectState(undefined)
    setRfa(undefined)
    setCancelling(false)
    setLoading(false)
  }

  const cancel = async (): Promise<void> => {
    if (startState?._id && (!collectState || collectState?.status === BankIdStatus.PENDING)) {
      const cancelId = startState._id
      setRfa(undefined)
      setStartState(undefined)
      setCollectState(undefined)
      setCancelling(true)
      setLoading(true)
      return cancelRequest(cancelId)
        .then(() => {
          setCancelling(false)
          setLoading(false)
          setRfa(undefined)
          reset()
          return
        })
        .catch(() => {
          reset()
          return
        })
    } else {
      reset()
    }
  }

  const beginStartState = async () => {
    setLoading(true)
    return authRequest({})
      .then((result: any) => {
        setStartState(result)
        return result
      })
      .catch((error: any) => {
        setLoading(false)
        setRfa(
          getByHintCode(
            error.code || BankIdOtherEnum.INTERNAL_ERROR,
            BankIdStatus.FAILED,
            translations,
          )
        )
        return undefined
      })
  }

  const startBankID = async () => {
    await cancel()

    setCollectState(undefined)
    setStartState(undefined)
    setRfa(undefined)
    return beginStartState()
  }

  const resetShallow = () => {
    setStartState(undefined)
    setCollectState(undefined)
    setRfa(undefined)
    beginStartState()
  }

  const value = {
    startState,
    setStartState,
    collectState,
    setCollectState,
    translations,
    authRequest,
    cancelRequest,
    pollRequest,
    reset,
    cancel,
    startBankID,
    resetShallow,
    rfa,
    setRfa,
    loading,
    setLoading,
    cancelling: cancelling || loading,
  }

  return <BankIdContext.Provider value={value as any} {...props} />
}

export { BankIdProvider, useBankId }
