import johnny from '../johnny'

class fetchr {
  options: RequestInit = {}
  url = ''
  constructor(url: string, token: string) {
    this.url = `${url}`
    this.options = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        Authorization: `Bearer ${token ? token : johnny.getToken()}`,
        'pragma': 'no-cache',
        'cache-control': 'no-cache',
        ...(johnny.getTestMode() && { 'x-briq-test-mode': '1' }),
      },
    }
  }
  post = (payload?: Record<string, unknown>) => this.execute('POST', payload)
  delete = (payload: Record<string, unknown>) => this.execute('DELETE', payload)
  put = (payload: Record<string, unknown>) => this.execute('PUT', payload)
  get = () => this.execute('GET')

  execute = (method: string, payload?: Record<string, unknown>) => {
    const body = payload ? { body: JSON.stringify(payload) } : {}
    const opts = { ...this.options, method, ...body }

    return fetch(this.url, opts).then(async (response) => {
      if (response.ok) return response

      if (response.status === 401) {
        return Promise.reject(response)
      }

      if (response.status < 200 || response.status > 299) {
        return Promise.reject(response)
      }

      const json = await response.json()
      return Promise.reject(json)
    })
  }
}

export default (url: string, token: string) => new fetchr(url, token)
